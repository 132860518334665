// @import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2019.11
CSS yang Document 2019.11
Last Update 2020.02 yang
---------------------------------*/
/*#################### PC.SP共通 ####################*/
/*
トップページ
 -------------------------------------*/
#Page.pageIndex {
  position: relative;
  #Header {
    .import {
      display: block;
      width: 100%;
      background-color: #111;
      background-image: url(../img/contents/arrow_02.png);
      background-repeat: no-repeat;
      .import_in {
        width: 100%;
        height: 100%;
        text-decoration: none;
        display: flex;
        justify-content: left;
        align-items: center;
        time {
          color: #888;
          letter-spacing: .2em;
        }
        p {
          margin: 0;
          color: #ddd;
          letter-spacing: .1em;
          line-height: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    #MainImg {
      .mainImg_slide_item01 {
        animation: slider01 24s -1.992s linear infinite;
      }
      .mainImg_slide_item02 {
        animation: slider02 24s linear infinite;
        .text {
          position: absolute;
          background: url(../img/contents/top/vertxt_11.png) no-repeat center top;
        }
      }
      .mainImg_slide_item03 {
        animation: slider03 24s linear infinite;
      }
    }
  }
  #Main {
    #ContBox00 {
      background: url(../img/contents/bg_02.jpg) top left;
      background-size: 50px auto;
      .bakedBox {
        &_in {
          width: 100%;
          display: flex;
          #BusinessHours {
            .bakedBox_cont {
              width: 100%;
              height: 100%;
            }
          }
        }
        &_cont {
          width: 50%;
          position: relative;
          border: 22px solid;
          border-image: url(../img/contents/frame_08.png) 44 round;
          .trapezoid {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            &:before, &:after {
              border-right: solid transparent;
              border-left: solid transparent;
            }
            &:before {
              border-bottom: solid #4a365b;
            }
            &:after {
              border-top: solid #4a365b;
            }
          }
        }
        &_left {
          ul {
            li {
              text-align: left;
              padding-left: 18px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: 6px;
                height: 6px;
                background-color: #4a365b;
                top: 13px;
                left: 3px;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
    #ContBox01 {
      h2 { background-image: url(../img/contents/top/title_01.png); }
      .contSubBox {
        h3 {
          display: inline-block;
          width: auto;
          height: auto;
          background: url(../img/contents/icon_01.png) no-repeat center top;
          text-align: center;
          &:after {
            display: block;
            content: '';
            width: 1px;
            height: 40px;
            background-color: #ddd;
            margin: 7px auto 0;
          }
        }
      }
      .subBox {
        position: relative;
        .subBoxImg {
          display: inline-block;
          height: 100%;
          float: left;
          position: relative;
          img { width: 100%; }
        }
        .subBoxTxt {
          display: inline-block;
          height: 100%;
          span {
            display: inline-block;
            background-position: top center;
            background-repeat: no-repeat;
            position: relative;
          }
          p {
            display: inline-block;
            background-position: top center;
            background-repeat: no-repeat;
            position: relative;
          }
        }
        .subBoxBtn {
          display: block;
    			background-image: none;
        }
      }
      .contSubBox01 {
        .subBox01 {
          .subBoxCont {
            background-image: url(../img/contents/top/bg_circle_01.png);
            background-repeat: no-repeat;
          }
          .subBoxTxt {
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_33.png);}
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_03.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_02.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_01.png); }
            }
            p {
              background-image: url(../img/contents/top/vertxt_04.png);
            }
          }
          .subBoxBtn {
            color: #613a83;
          }
        }
      }
      .contSubBox02 {
        background: url(../img/contents/bg_02.jpg) left top;
        background-size: 50px auto;
        .subBox {
          .subBoxTxt {
            span {
              background-size: 100% auto;
            }
          }
          .subBoxImg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .subBoxDetail {
            width: 100%;
            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              dt {
                img {
                  width: 100%;
                  height: auto;
                }
              }
              dd {
                p {
                  line-height: 24px;
                  margin-bottom: 0;
                  &.gray { color: #888; }
                }
              }
            }
          }
        }
        .subBox01 {
          .subBoxTxt {
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_21.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_19.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_18.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_17.png); }
              &:nth-of-type(5) { background-image: url(../img/contents/top/vertxt_16.png); }
            }
            p { background-image: url(../img/contents/top/vertxt_20.png); }
          }
          .subBoxImg { background-image: url(../img/contents/top/bg_circle_03.png); }
          .subBoxDetail {
            p {
              span { color: #d35c2a; }
            }
          }
          .subBoxBtn {
            color: #d35c2a;
            &:after { background-image: url(../img/contents/arrow_07.png); }
            &:hover {
              background-color: #f5ebe6;
              opacity: 1;
            }
          }
        }
        .subBox02 {
          .subBoxTxt {
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_27.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_25.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_24.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_23.png); }
              &:nth-of-type(5) { background-image: url(../img/contents/top/vertxt_22.png); }
            }
            p { background-image: url(../img/contents/top/vertxt_26.png); }
          }
          .subBoxImg {
            background-image: url(../img/contents/top/bg_circle_02.png);
            span {
              position: absolute;
              font-size: 12px;
              color: #888;
              letter-spacing: 0.1em;
            }
          }
          .subBoxDetail {
            p {
              span { color: #b0640c; }
            }
          }
          .subBoxBtn {
            color: #b3670f;
            &:after { background-image: url(../img/contents/arrow_06.png); }
            &:hover {
              background-color: #f4ede5;
              opacity: 1;
            }
          }
        }
        .btnArea {
          // display: flex;
          display: none;
          justify-content: center;
          align-items: center;
          a {
            display: block;
            text-align: right;
            text-decoration: none;
            span {
              display: inline-block;
              letter-spacing: 0.3em;
              position: relative;
              &:after {
                position: absolute;
                content: '';
                right: 0;
                background-position: top right;
                background-size: 100% auto;
              }
            }
            &:nth-of-type(1) {
              span {
                background-image: url(../img/contents/top/img_19.png);
                background-repeat: no-repeat;
                color: #b0640c;
                &:after {
                  background-image: url(../img/contents/arrow_06.png);
                }
              }
              &:hover {
                background-color: #f4ede5;
                opacity: 1;
              }
            }
            &:nth-of-type(2) {
              span {
                background-image: url(../img/contents/top/img_28.png);
                background-repeat: no-repeat;
                color: #d35c2a;
                &:after {
                  background-image: url(../img/contents/arrow_07.png);
                }
              }
              &:hover {
                background-color: #f5ebe6;
                opacity: 1;
              }
            }
          }
        }
      }
      .contSubBox03 {
        display: none;
        .txtArea {
          background: url(../img/contents/bg_05.jpg) top left;
          background-size: 50px 50px;
          h3 {
            &:after { background-color: #d1cbd6; }
          }
          .listWrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .listItem {
            p { text-align: justify; }
          }
        }
      }
      .contSubBox04 {
        .subBox {
          .subBoxTxt {
            p {
              background-size: 100% auto;
              margin-bottom: 0;
            }
          }
          .subBoxImg {
            width: 100%;
            height: auto;
            float: none;
            margin-top: 16px;
            // margin-bottom: 15px;
            a { margin: 0 auto; }
            p {
              font-size: 12px;
              color: #888;
              letter-spacing: 0.1em;
              line-height: 24px;
              text-align: center;
              margin-top: 16px;
              margin-bottom: 0;
            }
          }
          .subBoxItem {
            display: flex;
            justify-content: center;
            width: 100%;
            img + img { margin-left: 10px; }
          }
        }
        .subBox01 {
          .subBoxTxt {
            p { background-image: url(../img/contents/top/vertxt_34.png); }
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_35.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_03_02.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_08.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_07.png); }
            }
          }
        }
        .subBox02 {
          .subBoxTxt {
            p { background-image: url(../img/contents/top/vertxt_36.png); }
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_37.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_03_03.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_13.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_07_02.png); }
            }
          }
        }
      }
      .contSubBox05 {
        background: url(../img/contents/bg_02.jpg) left top;
        background-size: 50px auto;
        .subBox {
          margin: 0 auto;
          .subBoxCont {
            height: auto;
            background: url(../img/contents/top/bg_circle_04.png) no-repeat center top;
          }
          .subBoxTxt {
            p {
              background: url(../img/contents/top/vertxt_31.png) no-repeat left top;
              background-size: 100% auto;
              margin-bottom: 0;
            }
            span { background-image: url(../img/contents/top/vertxt_30_01.png); }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_02.jpg) top left;
      background-size: 50px 50px;
      h2 { background-image: url(../img/contents/top/title_02.png); }
      .contSubBox {
        padding: 0;
        position: relative;
        .clickArea {
          width: 100%;
          position: relative;
          background-position: center center;
          background-size: cover;
          .btnTxt {
            width: 100%;
            position: absolute;
            color: #fff;
            h3 {
              letter-spacing: .2em;
              text-shadow: #000 0 0 10px;
            }
          }
        }
        .subBoxInner {
          h4 {
            display: inline-block;
            width: auto;
            height: auto;
            text-align: center;
            background: url(../img/contents/icon_01.png) no-repeat top center;
            margin: 0 auto;
            &::after {
              content: '';
              display: block;
              width: 1px;
              height: 42px;
              background: #ddd;
              margin: 0 auto;
              margin-top: 7px;
            }
          }
          p, small { font-size: 14px; }
          small {
            display: block;
            text-align: right;
          }
        }
      }
      .contSubBox01 {
        .boxTxt {
          a {
            display: block;
            text-align: left;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              background: no-repeat center bottom;
            }
            &:nth-of-type(1):before {
              background-image: url(../img/contents/top/img_21.png);
            }
            &:nth-of-type(2):before {
              background-image: url(../img/contents/top/img_22.png);
            }
            &:nth-of-type(3):before {
              background-image: url(../img/contents/top/img_23.png);
            }
          }
        }
        .boxBtn {
          display: block;
          margin: 0 auto;
    			span {
    				display: inline-block;
    				background-position: right 10px !important;
    			}
        }
      }
      .contSubBox02 {
        .subBoxInner {
          .box01 {
            .boxCont {
              .left {
                p { letter-spacing: .1em; }
              }
              .right {
                background: url(../img/contents/pc/frame_02.png) no-repeat;
                h5 { letter-spacing: .25em; }
                img { width: 100%; }
              }
            }
      			.boxBtn {
      			  display: block;
      			  margin: 0 auto;
      				span{
      					display: inline-block;
      				background-position: right 10px !important;
      				}
      			}
          }
        }
      }
    }
    #ContBox03 {
      h2 { background-image: url(../img/contents/top/title_03.png); }
      .leaderText {
        p {
          text-align: center;
          letter-spacing: 0.1em;
          margin-bottom: 0;
        }
        & > span {
          display: block;
          color: #888;
        }
      }
      .boxImage {
        .verTxt {
          text-orientation: mixed;
          span {
            -webkit-text-combine: horizontal;
            -ms-text-combine-horizontal: all;
            text-combine-upright: all;
          }
        }
      }
      .boxTxt {
        p {
          width: 100%;
          letter-spacing: .1em;
          span::after {
            content: '';
            display: inline-block;
            background: url(../img/contents/icon_05.png) top left;
            position: relative;
          }
        }
      }
      .boxTable {
        width: 100%;
        tr {
          letter-spacing: .1em;
          border-top: 1px solid #ddd;
          // border-bottom: 1px solid #ddd;
          th {
            width: 80px;
            font-weight: normal;
          }
          td {
            a {
              display: inline-block;
              &.sp_display { display: none; }
            }
          }
          &.link td {
            a {
              display: block;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }

      .contSubBox02 { display: none !important; }
    }
    #ContBox04 {
      background-image: url(../img/contents/bg_02.jpg);
      background-size: 50px 50px;
      & > *:not(.bottomImg) { display: none; }
      &:before { display: none; }
      h2 { background-image: url(../img/contents/top/title_04.png); }
      .listBoxArea {
        .stickyBox span { left: 6px; }
      }
      .bottomImg {
        width: 100%;
        height: 110px;
        // margin-top: 60px;
        margin-top: 0 !important;
        background: url(../img/contents/top/img_12.jpg) center;
        background-size: cover;
      }
    }
    #ContBox05 {
      h2 { background-image: url(../img/contents/top/title_05.png); }
    }
  }
}

@keyframes slider01 {
  0% { opacity: 0; }
  8.3% { opacity: 1; }
  33.3% { opacity: 1; }
  41.6% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes slider02 {
  0% { opacity: 0; }
  25.1% { opacity: 0; }
  33.4% { opacity: 1; }
  58.4% { opacity: 1; }
  66.7% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes slider03 {
  0% { opacity: 0; }
  27% { opacity: 0; }
  58.4% { opacity: 0; }
  66.7% { opacity: 1; }
  91.7% { opacity: 1; }
  100% { opacity: 0; }
}


/*
お問合せ 確認画面
-------------------------------------*/
#PageContact.pageConfirm {
  #Main {
    #ContBox01 {
      h2 {
        background-image: url(../img/contents/contact/title.png);
        margin-bottom: 37px;
      }
      .txtWrong {
        margin-bottom: 23px;
        p {
          margin-bottom: 0;
          &:first-child {
            margin-bottom: 14px;
          }
        }
      }
      #ContSubBox01 {
        .txtArea {
          p {
            letter-spacing: .1em;
            margin-bottom: 0;
          }
        }
        .formArea {
          width: 100%;
          h3 {
            width: 100%;
            text-align: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
          .formInner {
            th {
              display: block;
              font-weight: inherit;
              text-align: left;
              letter-spacing: .1em;
              &::before {
                display: inline-block;
                text-align: center;
                margin-right: 10px;
              }
            }
            td {
              display: block;
              width: 100%;
              height: auto;
              border: none;
              letter-spacing: .1em;
            }
            .right {
              tr:nth-of-type(5) { display: none; }
              th::before {
                content: '必須';
                background: #000;
                color: #fff;
              }
            }
            .left {
              th::before {
                content: '任意';
                background: #ddd;
              }
            }
            tbody:last-of-type {
              th:before {
                content: '必須';
                background: #000;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

/*
お問合せ 送信完了画面
-------------------------------------*/
#PageContact.pageThanks {
  #Main {
    #ContBox01 {
      h2 { background-image: url(../img/contents/contact/title.png); }
    }
  }
}

/*
新着情報
-------------------------------------*/
#PageNews.pageIndex {}

/*
新着情報 カテゴリー
-------------------------------------*/
#PageNews.pageCategory {
  #Main {
    #ContBox01 {
      .listMenu {
        .current-cat {
          position: relative;
          a {
            color: #000;
            border-bottom: none;
          }
          &::after {
            content: '';
            width: 1px;
            background: #ddd;
            position: absolute;
          }
        }
      }
    }
  }
}

/*
新着情報 記事詳細
-------------------------------------*/
#PageNews.pageEntry {
  #Main {
    #ContBox01 {
      .contSubBox {
        text-align: left;
        h3 {
          width: 100%;
          text-align: justify !important;
          word-break: break-all;
        }
        time { color: #888; }
        .label {
          display: inline-block;
          text-align: center;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          margin-right: 15px;
          position: relative;
          &::before,&::after {
            content: '';
            position: absolute;
            background: url(../img/contents/frame_02.png) no-repeat top left;
          }
          &::before { top: -1px; }
          &::after {
            top: -1px;
            transform: rotateY(180deg);
          }
          &:last-of-type { margin-right: 0; }
        }
        .entryBox {
          width: 100%;
          border-bottom: 1px solid #ddd;
          position: relative;
          p {
            letter-spacing: .1em;
            word-break: break-all;
          }
          .figerBox {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
      .linkArea {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        li {
          position: absolute;
          a {
            display: block;
            text-decoration: none;
            background-repeat: no-repeat;
          }
        }
        li.prev {
          a {
            background-image: url(../img/contents/arrow_04.png);
            background-position: left center;
            text-align: right;
          }
        }
        li.next {
          a {
            background-image: url(../img/contents/arrow_03.png);
            background-position: right center;
            text-align: left;
          }
        }
      }
    }
  }
}

/*
商品のご予約
-------------------------------------*/
#PageReservation,
#PageYukigaya {
  #FormBtn{
    display: none;
  }
}
#PageReservation.pageIndex,
#PageYukigaya.pageIndex {
  #Main {
    #ContBox01 {
      h2 {
        background-image: url(../img/contents/reservation/title_sp.png);
        background-position: bottom center;
        background-repeat: no-repeat;
      }
    }
    .mainImg {
      width: 100%;
      background: url(../img/contents/reservation/mainimg.jpg) no-repeat center center;
      background-size: cover;
      .mainImgInner {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        p {
          position: absolute;
          background-color: #4a365b;
          color: #fff;
          letter-spacing: 0.3em;
          text-align: center;
          white-space: nowrap;
          writing-mode: vertical-lr;
          text-orientation: mixed;
          margin: 0;
          left: 0;
          span {
            -webkit-text-combine: horizontal;
            -ms-text-combine-horizontal: all;
            text-combine-upright: all;
          }
          &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 100% auto;
          }
        }
      }
    }
    #ContSubBox01 {
      .txtArea {
        h3 {
          font-size: 20px;
          letter-spacing: 0.25em;
          line-height: 24px;
          background: url(../img/contents/icon_01.png) no-repeat center top;
          position: relative;
          margin-bottom: 89px;
          &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 40px;
            background-color: #ddd;
            left: 0;
            right: 0;
            bottom: -69px;
            margin: 0 auto;
          }
        }
        .table {
          position: relative;
          table {
            width: 100%;
            th, td {
              font-size: 14px;
              letter-spacing: 0.1em;
              line-height: 24px;
              text-align: center;
              vertical-align: middle;
              border: 1px solid #d1cbd6;
              .red {
                color: #cc0303;
                display: inline-block;
              }
              .small {
                color: #000;
                display: inline-block;
              }
              .start {
                vertical-align: super;
                font-size: 7px !important;
                position: relative;
                top: 2px;
              }
            }
            th {
              background: url(../img/contents/bg_03.jpg) left top;
              background-size: 50px auto;
              font-weight: normal;
            }
          }
        }
        .termTable {
          td p {
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 24px;
            text-align: left;
            margin-bottom: 0;
          }
        }
        .noteLists {
          .note {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: #cc0303;
            text-align: left;
            position: relative;
            padding-left: 1.1em;
            &:before {
              position: absolute;
              content: "※";
              top: 0;
              left: 0;
            }
          }
        }
      }
      .formArea {
        .formPart {
          position: relative;
          .formInner {
            tr:nth-of-type(5) td > .radioBtn {
              margin-top: 10px;
            }
            th {
              position: relative;
              span {
                position: absolute;
                margin-right: 0;
                top: 3px;
                left: 0;
              }
            }
            td {
              height: 100%;
              .radio {
                span {
                  border: none;
                  background-color: transparent;
                  background: url(../img/contents/reservation/icon_01.png) no-repeat top left;
                  padding: 0;
                  border-radius: 50%;
                  &.checked { background-image: url(../img/contents/reservation/icon_02.png); }
                  input {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                  }
                }
              }
              .bag {
                .radioBtn {
                  display: inline-block;
                }
                input[type="number"] {
                  display: inline-block;
                  width: 124px;
                  margin: 0 10px 0 12px;
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                }
              }
              input[data-toggle="datepicker"] {
                width: 100% !important;
                background: #fff url(../img/contents/icon_06.png) no-repeat center right 14px;
                background-size: 6px 7px;
                &:hover { cursor: default; }
                &::placeholder { color: #000; }
              }
              p {
                letter-spacing: .1em;
                margin-bottom: 0;
                transform: translateY(3px);
                & > * { transform: translateY(-3px); }
              }
              label {
                display: block;
                letter-spacing: .1em;
                transform: translateY(3px);
                & > * { transform: translateY(-3px); }
                &:nth-of-type(2) { margin-top: 10px; }
              }
              small {
                display: block;
                color: #888;
                letter-spacing: .1em;
                padding-left: 16px;
                position: relative;
                &:before {
                  position: absolute;
                  content: '※';
                  top: 1px;
                  left: 0;
                }
              }
              textarea {
                width: 100%;
                border: 1px solid #ddd;
                background-color: #fff;
              }
            }
          }
        }
        .formPart01 {
          .formInner {
            tr {
              &:nth-of-type(3) td {
                p { transform: translate(0); }
                dl {
                  dt {
                    position: relative;
                    letter-spacing: 0.1em;
                    &:before {
                      position: absolute;
                      content: '';
                      background: url(../img/contents/icon_01.png) no-repeat center center;
                    }
                    small {
                      padding-left: 0;
                      &:before { content: ''; }
                    }
                  }
                  dd {
                    width: 100%;
                    display: flex;
                  }
                }
              }
            }
            td {
              p { line-height: 1.5; }
            }
          }
        }
        .sendBtn {
          .btn input { z-index: 1; }
        }
      }
    }
  }
  .ui-datepicker-calendar .ui-datepicker-today {
    opacity: 1 !important;
  }

  .ui-widget-content .ui-datepicker-today > .ui-state-default {
    border: 1px solid #dad55e;
    background: #fffa90;
    color: #777620;
  }
}

/*■■■■■■ 雪が谷 ■■■■■■*/
#PageYukigaya.pageIndex {
  #Main {
    #ContBox01 .mainImg {
      background-image: url(../img/contents/reservation/mainimg_yuki.jpg) !important;
    }
  }
}

/*
商品のご予約 確認画面
-------------------------------------*/
#PageReservation.pageConfirm,
#PageYukigaya.pageConfirm {
  #Main {
    #ContBox01 {
      h2 { background-image: url(../img/contents/reservation/title_sp.png); }
      .txtWrong {
        margin-bottom: 23px;
        p {
          margin-bottom: 0;
          &:first-child {
            margin-bottom: 14px;
          }
        }
      }
      #ContSubBox01 {
        .txtArea {
          p {
            letter-spacing: .1em;
            margin-bottom: 0;
          }
        }
        .formArea {
          width: 100%;
          h3 {
            width: 100%;
            text-align: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
          .formInner {
            th {
              display: block;
              font-weight: inherit;
              text-align: left;
              letter-spacing: .1em;
              position: relative;
              &::before {
                position: absolute;
                text-align: center;
                top: 1px;
                left: 0;
              }
            }
            td {
              display: block;
              width: 100%;
              height: auto;
              border: none;
              letter-spacing: .1em;
            }
          }
          .formPart01 {
            th::before {
              content: '必須';
              background: #000;
              color: #fff;
            }
            tr:nth-of-type(3) {
              td {
                dl {
                  dt {
                    position: relative;
                    &:before {
                      position: absolute;
                      content: '';
                      background: url(../img/contents/icon_01.png) no-repeat center center;
                    }
                  }
                }
              }
            }
            tr:nth-of-type(4), tr:nth-of-type(5), tr:nth-of-type(6) {
              th::before {
                content: '任意';
                background: #ddd;
                color: #000
              }
            }
          }
          .formPart02 {
            th::before {
              content: '必須';
              background: #000;
              color: #fff;
            }
            tr:nth-of-type(4) { display: none; }
            // tr:last-of-type {
            //   th::before {
            //     content: '任意';
            //     background: #ddd;
            //     color: #000
            //   }
            // }
          }
        }
      }
    }
  }
}

/*
商品のご予約 送信完了画面
-------------------------------------*/
#PageReservation.pageThanks,
#PageYukigaya.pageThanks {
  #Main {
    #ContBox01 {
      h2 { background-image: url(../img/contents/reservation/title_sp.png); }
    }
  }
}

/*
商品のご注文
-------------------------------------*/
#PageOrder {
  #Main {
    #ContBox01 h2 {
      background-image: url(../img/contents/order/title.png);
    }
  }
}
#PageOrder.pageIndex {
  #Main {
    .pageNaviWrap {
      .navi {
        a {
          color: #613a83;
          letter-spacing: .15em;
          line-height: 1;
          text-decoration: none;
          background-image: url(../img/contents/arrow_05.png);
          background-repeat: no-repeat;
        }
      }
    }
    ul {
      text-align: left;
      li {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
        }
      }
    }
    .note {
      position: relative;
      color: #888;
      text-align: left;
      letter-spacing: .1em;
      &:before {
        position: absolute;
        content: '※';
        top: 0;
        left: 0;
      }
    }
    .kanji {
      &:nth-of-type(1):before { content: '一、'; }
      &:nth-of-type(2):before { content: '二、'; }
      &:nth-of-type(3):before { content: '三、'; }
      &:nth-of-type(4):before { content: '四、'; }
    }
    .num {
      &:nth-of-type(1):before { content: '①'; }
      &:nth-of-type(2):before { content: '②'; }
      &:nth-of-type(3):before { content: '③'; }
      &:nth-of-type(4):before { content: '④'; }
    }
    .boxTitle {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      height: 41px;
      line-height: 41px;
      span {
        letter-spacing: .25em;
        margin-right: -.25em;
      }
    }
    table.txtTable {
      width: 100%;
      table-layout: fixed;
      tr {
        &:last-of-type {
          th, td { border-bottom-width: 1px; }
        }
      }
      th {
        font-weight: normal;
        border: 1px solid #d1cbd6;
        border-bottom-width: 0;
        background: url(../img/contents/bg_03.jpg) top left;
        background-size: 50px 50px;
        img { width: 100%; }
        a[rel^=lightbox] { display: block; width: 100%; height: 100%; }
      }
      td {
        border: 1px solid #d1cbd6;
        border-bottom-width: 0;
        border-left-width: 0;
        &.bg_purple {
          background: url(../img/contents/bg_03.jpg) top left;
          background-size: 50px 50px;
        }
      }
    }
    table.formInner {
      div.radio {
        border-radius: 50%;
        span {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          padding: 0;
          input {
            padding: 0;
            width: 100%;
            height: 100%;
            border-width: 0;
          }
          &.checked {
            position: relative;
            &:before {
              position: absolute;
              content: '';
              background-color: #613a83;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      span.note {
        display: block;
        height: auto;
        border: none;
        background-color: transparent;
      }
      input[data-toggle="datepicker"] {
        background: #fff url(../img/contents/icon_06.png) no-repeat center right 14px;
        background-size: 6px 7px;
        cursor: default;
        &::placeholder { color: #000; }
      }
    }
    .lawBoxWrap {
      position: relative;
      border: 8px solid;
      border-image: url(../img/contents/order/border.png) 16 round;
      .lawBoxTitle {
        font-size: 14px;
        color: #613a83;
        text-align: center;
        line-height: 1;
        padding: 11px 0 18px;
        span {
          display: inline-block;
          letter-spacing: .3em;
          margin-right: -.3em;
        }
        &:after {
          position: absolute;
          content: '';
          width: calc(100% + 16px);
          height: 1px;
          background-color: #613a83;
          top: 42px;
          left: -8px;
        }
      }
      .lawBoxCont {
        dl {
          border-bottom: 1px solid #ddd;
          display: flex;
          justify-content: start;
          align-items: left;
          line-height: 1.7;
          letter-spacing: .1em;
          &:last-of-type { border-bottom: 0; }
        }
        dt { padding-right: 23px; }
        dd { color: #888; }
      }
      .mCustomScrollbar {
        .mCSB_container { margin-right: 0; }
        #mCSB_1_scrollbar_vertical {
          opacity: 1;
          position: absolute;
          .mCSB_dragger_bar {
            background-color: #d1cbd6;
            border-radius: 0;
          }
          .mCSB_draggerRail { background-color: transparent; }
        }
      }
      &:before, &:after {
        position: absolute;
        content: '';
        width: 95%;
        background: url(../img/contents/bg_01.jpg) top left;
        background-size: 50px auto;
        left: 0;
      }
    }
    #uniform-check {
      span {
        background-color: #fff;
        border: 1px solid #ddd;
        input { width: 100%; height: 100%; }
        &.checked {
          position: relative;
          &:before {
            position: absolute;
            content: '';
            background: url(../img/contents/icon_check.png) no-repeat center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .ui-datepicker-calendar .ui-datepicker-today {
    opacity: 1 !important;
  }

  .ui-widget-content .ui-datepicker-today > .ui-state-default {
    border: 1px solid #dad55e;
    background: #fffa90;
    color: #777620;
  }
}

/*
商品のご注文 確認画面
-------------------------------------*/
#PageOrder.pageConfirm {
  #Main {
    #ContBox01 {
      .contSubBox {
        .txtArea {
          p { letter-spacing: .1em; }
        }
        .formArea {
          h3 {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            span {
              letter-spacing: .25em;
              margin-right: -.25em;
            }
          }
          .formInner {
            width: 100%;
            tr {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
            }
            th {
              font-weight: normal;
              letter-spacing: .1em;
              &:before {
                position: relative;
                display: inline-block;
                letter-spacing: .2em;
                margin-right: 10px;
              }
              &.must:before {
                content: '必須';
                background-color: #000;
                color: #fff;
              }
              &.any:before {
                content: '任意';
                background-color: #ddd;
                color: #000;
              }
            }
            td {
              letter-spacing: .1em;
              span.note {
                color:#888;
                position: relative;
                display: block;
                &:before {
                  position: absolute;
                  content: '※';
                  width: auto;
                  height: 100%;
                  left: 0;
                  top: 0;
                }
              }
              p { margin: 0; }
            }
          }
        }
      }
    }
  }
}

/*
商品のご注文 送信完了画面
-------------------------------------*/
#PageOrder.pageThanks {
  position: relative;
  min-height: 100vh;
  #Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

/*
商品紹介
-------------------------------------*/
#PageItem {
  #Main {
    .contBox {
      h2 {
        background-image: url(../img/contents/item/title.png);
      }
      img {
        width: 100%;
      }
      .subBoxTable {
        width: 100%;
        th {
          letter-spacing: .1em;
          background: url(../img/contents/bg_03.jpg) top left;
          background-size: 50px 50px;
          border: 1px solid #d1cbd6;
          font-weight: 400;
        }
        td {
          letter-spacing: .1em;
          line-height: 1.7;
          border: 1px solid #d1cbd6;
          small {
            display: block;
            line-height: 1.5;
            letter-spacing: .09em;
          }
          .reportBox {
            display: block;
            figure{
              img{
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
      .subBoxBtn {
        .btn {
          position: relative;
          color: #fff;
          letter-spacing: .2em;
          text-decoration: none;
          &:before, &:after {
            position: absolute;
            top: 0;
            content: '';
            width: 12px;
            height: 100%;
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 12px auto;
            transition: background-image .3s linear;
          }
          &:before {
            left: -12px;
          }
          &:after {
            right: -12px;
          }
          &:first-of-type { margin-right: 0 !important; }
        }
        .btn_reservation { display: none !important; }
      }
    }
    #ContBox01 {
      .subBox {
        &_left {
          .subBoxTxt {
            width: 100%;
            border: 22px solid;
            border-image: url(../img/contents/frame_08.png) 44 round;
            position: relative;
          }
          .trapezoid {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            &:before, &:after {
              border-right: solid transparent;
              border-left: solid transparent;
            }
            &:after {
              bottom: 1px;
            }
          }
          h3 {
            position: relative;
            letter-spacing: .25em;
          }
          .txtList {
            ul {
              text-align: left;
              li {
                letter-spacing: .2em;
                position: relative;
                padding-left: 15px;
                &:before {
                  position: absolute;
                  content: '';
                  width: 6px;
                  height: 6px;
                  top: 8.5px;
                  left: 1px;
                  transform: translateY(-50%) rotate(45deg);
                }
              }
            }
            img {
              width: 100%;
              height: auto;
            }
            .btn {
              display: block;
              width: 100%;
              letter-spacing: .3em;
              text-decoration: none;
      				span {
      					display: inline-block;
      					background-position: right 5px !important;
      				}
            }
          }
        }
        &_right {
          background-repeat: no-repeat;
          .subBoxImg {
            position: relative;
            display: inline-block;
            float: left;
            height: 100%;
            img {
              width: 100%;
              position: relative;
            }
            span { color: #888; }
          }
          .subBoxCont {
            height: 100%;
            position: relative;
          }
          .subBoxTxt {
            display: inline-block;
            height: 100%;
            float: right;
            position: relative;
            span, p {
              background-repeat: no-repeat;
              background-position: left center;
              position: relative;
              display: inline-block;
            }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_02.jpg) left top;
      background-size: 50px auto;
      h3 {
        position: relative;
        background-position: center top;
        background-repeat: no-repeat;
        &:after {
          position: absolute;
          content: '';
          width: 1px;
          height: 40px;
          background-color: #ddd;
          bottom: -64px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .boxTxt {
        p {
          letter-spacing: .1em;
        }
        small {
          display: block;
          letter-spacing: .1em;
          text-align: right;
        }
      }
    }
  }
}

/*
商品紹介 三日月
-------------------------------------*/
#PageItem.pageMikaduki {
  #Main {
    #ContBox01 {
      .subBox {
        &_left {
          .trapezoid {
            &:before {
              border-bottom: solid #4a365b;
            }
            &:after {
              border-top: solid #4a365b;
            }
          }
          .txtList {
            ul {
              li {
                &:before {
                  background-color: #4a365b;
                }
              }
            }
            .btn span {
              background-image: url(../img/contents/arrow_03.png);
            }
          }
        }
        &_right {
          background-image: url(../img/contents/top/bg_circle_01.png);
          position: relative;
          .subBoxTxt {
            span {
              &:nth-of-type(1){
                background-image: url(../img/contents/top/vertxt_33.png);
              }
              &:nth-of-type(2){
                background-image: url(../img/contents/top/vertxt_03.png);
              }
              &:nth-of-type(3){
                background-image: url(../img/contents/top/vertxt_02.png);
              }
              &:nth-of-type(4){
                background-image: url(../img/contents/top/vertxt_01.png);
              }
            }
            p {
              background-image: url(../img/contents/top/vertxt_04.png);
            }
          }
        }
      }
      .subBoxNote {
        display: none !important;
        .flexBox {
          display: flex;
          flex-wrap: wrap;
          .item {
            p {
              font-size: 14px;
              letter-spacing: 0.1em;
              line-height: 24px;
              text-align: center;
              margin-bottom: 4px;
              small {
                font-size: 12px;
              }
            }
            a {
              font-size: 12px;
              letter-spacing: 0.1em;
              line-height: 20px;
            }
          }
        }
        .flexBox01 {
          .item {
            p span {
              position: relative;
              &:before {
                position: absolute;
                width: 30px;
                content: '';
                border-top: 1px solid #000;
                top: 9px;
                left: -41px;
              }
              &:after {
                position: absolute;
                width: 30px;
                content: '';
                border-top: 1px solid #000;
                top: 9px;
                right: -39px;
              }
            }
          }
        }
        .flexBox02 {
          border: solid #dddddd;
          border-width: 1px 0 1px 0;
          .item {
            div {
              p {
                line-height: 20px !important;
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }
      .subBoxTable {
        display: none !important;
      }
    }
    #ContBox02 {
      h3 {
        background-image: url(../img/contents/icon_01.png);
      }
    }
    #ContBox03 {
      .contSubBox {
        h3 {
          position: relative;
          background-image: url(../img/contents/icon_01.png);
          background-position: center top;
          background-repeat: no-repeat;
          &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 40px;
            background-color: #ddd;
            bottom: -64px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .subBox {
          .subBoxCont {
            & > p {
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              margin: 12px 0 0;
            }
          }
          .subBoxImg {
            display: flex;
            justify-content: center;
            align-items: center;
            img { height: auto; }
          }
          .subBoxTxt {
            span, p {
              background-repeat: no-repeat;
              background-position: left top;
              background-size: 100% auto;
            }
            span { width: 18px; }
            p { margin-bottom: 0; }
          }
          .subBoxDetail {
            display: flex;
            flex-wrap: wrap;
            .item {
              position: relative;
              background-position: left top;
              background-size: 50px auto;
              display: flex;
              &:before, &:after {
                position: absolute;
                left: 0;
                display: block;
                content: '';
                width: 100%;
                height: 20px;
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 100% 20px;
              }
            }
          }
          .subBoxSur {
            position: relative;
            border: 21px solid;
            border-image: url(../img/contents/item/frame_pc_04.png) 42 round;
            display: flex;
            flex-wrap: wrap;
            .trapezoid {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              &:before, &:after {
                border-right: solid transparent;
                border-left: solid transparent;
              }
            }
            .sourse {
              p {
                position: relative;
                margin-bottom: 0;
                &:before {
                  position: absolute;
                  content: '';
                  background-repeat: no-repeat;
                  background-position: left top;
                  background-size: 100% auto;
                  left: 0;
                }
              }
            }
          }
        }
        .subBox01 {
          .subBoxTxt {
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_21.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_19.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_18.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_17.png); }
              &:nth-of-type(5) { background-image: url(../img/contents/top/vertxt_16.png); }
            }
          }
          .subBoxImg { background-image: url(../img/contents/top/bg_circle_03.png); }
          .subBoxDetail {
            .item {
              background-image: url(../img/contents/item/boxBg_02.png);
              &:before {
                background-image: url(../img/contents/item/boxBg_01.png);
                top: -20px;
              }
              &:after {
                background-image: url(../img/contents/item/boxBg_03.png);
                bottom: -20px;
              }
              dt {
                p span { color: #d35c2a; }
              }
            }
          }
          .subBoxSur {
            .trapezoid {
              &:before {
                border-bottom: solid #ea703d;
              }
              &:after {
                border-top: solid #ea703d;
              }
            }
            .sourse {
              p {
                &:before {
                  background-image: url(../img/contents/item/icon_01.png);
                }
              }
            }
          }
        }
        .subBox02 {
          .subBoxTxt {
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_27.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_25.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_24.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_23.png); }
              &:nth-of-type(5) { background-image: url(../img/contents/top/vertxt_22.png); }
            }
          }
          .subBoxImg {
            background-image: url(../img/contents/top/bg_circle_02.png);
            position: relative;
            span {
              position: absolute;
              font-size: 12px;
              color: #888;
              letter-spacing: 0.1em;
              line-height: 20px;
            }
          }
          .subBoxDetail {
            .item {
              background-image: url(../img/contents/item/boxBg_05.png);
              &:before {
                background-image: url(../img/contents/item/boxBg_04.png);
                top: -20px;
              }
              &:after {
                background-image: url(../img/contents/item/boxBg_06.png);
                bottom: -20px;
              }
              dt {
                p span { color: #b0640c; }
              }
            }
          }
          .subBoxSur {
            .trapezoid {
              &:before {
                border-bottom: solid #845620;
              }
              &:after {
                border-top: solid #845620;
              }
            }
            .sourse {
              p {
                &:before {
                  background-image: url(../img/contents/item/icon_02.png);
                }
              }
            }
          }
        }
        .subBox03 {
          table {
            td {
              .note {
                li {
                  font-size: 12px;
                  letter-spacing: 0.1em;
                  padding-left: 0;
                  position: relative;
                  counter-increment: chapter;
                  &:before {
                    position: absolute;
                    content: "※" counter(chapter);
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ContBox04 {
      background: url(../img/contents/bg_02.jpg) left top;
      background-size: 50px auto;
      .contSubBox {
        .subBox {
          .subBoxCont { position: relative; }
          .subBoxTxt {
            width: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            span, p {
              position: absolute;
              background-repeat: no-repeat;
              background-position: left top;
              background-size: 100% auto;
            }
            span { width: 18px; }
            p { margin-bottom: 0; }
          }
          .subBoxImg {
            width: 100%;
            height: auto;
            a { margin: 0 auto; }
            p {
              font-size: 12px;
              color: #888;
              letter-spacing: 0.1em;
              line-height: 24px;
              text-align: center;
              margin-bottom: 0;
            }
          }
          .subBoxItem {
            display: flex;
            justify-content: center;
            width: 100%;
            img + img { margin-left: 10px; }
          }
        }
        .subBox01 {
          .subBoxTxt {
            p { background-image: url(../img/contents/top/vertxt_34.png); }
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_35.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_03_02.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_08.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_07.png); }
            }
          }
        }
        .subBox02 {
          .subBoxTxt {
            p { background-image: url(../img/contents/top/vertxt_36.png); }
            span {
              &:nth-of-type(1) { background-image: url(../img/contents/top/vertxt_37.png); }
              &:nth-of-type(2) { background-image: url(../img/contents/top/vertxt_03_03.png); }
              &:nth-of-type(3) { background-image: url(../img/contents/top/vertxt_13.png); }
              &:nth-of-type(4) { background-image: url(../img/contents/top/vertxt_07_02.png); }
            }
          }
        }
      }
    }
    #ContBox05 {
      .contSubBox {
        h3 {
          position: relative;
          background-image: url(../img/contents/icon_01.png);
          background-position: center top;
          background-repeat: no-repeat;
          &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 40px;
            background-color: #ddd;
            bottom: -64px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .subBox {
          .subBoxCont {
            height: auto;
            background: url(../img/contents/top/bg_circle_04.png) no-repeat center top;
            position: relative;
          }
          .subBoxTxt {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            p {
              position: absolute;
              background: url(../img/contents/item/vertxt_04.png) no-repeat left top;
              background-size: 100% auto;
              margin-bottom: 0;
            }
            span {
              background: url(../img/contents/top/vertxt_30_01.png) no-repeat left top;
              background-size: 100% auto;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

/*
商品紹介 三日乃陽
-------------------------------------*/
#PageItem.pageHinata {
  #Main {
    a { color: #b0640c; }
    #ContBox01 {
      .subBox {
        &_left {
          .trapezoid {
            &:before {
              border-bottom: solid #845620;
            }
            &:after {
              border-top: solid #845620;
            }
          }
          .txtList {
            ul {
              li {
                &:before {
                  background-color: #885417;
                }
              }
            }
            .btn span {
              background-image: url(../img/contents/arrow_06.png);
            }
          }
        }
        &_right {
          background-image: url(../img/contents/top/bg_circle_02.png);
          .subBoxTxt {
            span {
              &:nth-of-type(1){
                background-image: url(../img/contents/top/vertxt_10.png);
              }
              &:nth-of-type(2){
                background-image: url(../img/contents/top/vertxt_03_02.png);
              }
              &:nth-of-type(3){
                background-image: url(../img/contents/top/vertxt_08.png);
              }
              &:nth-of-type(4){
                background-image: url(../img/contents/top/vertxt_07.png);
              }
            }
            p {
              background-image: url(../img/contents/top/vertxt_09.png);
            }
          }
        }
      }
      .subBoxImg span small { color: red; }
      .subBoxTable {
        th {
          background-image: url(../img/contents/bg_06.jpg);
          border: 1px solid #dccebe;
        }
        td {
          border: 1px solid #dccebe;
        }
      }
    }
    #ContBox02 {
      h3 {
        background-image: url(../img/contents/icon_12.png);
      }
    }
  }
}

/*
商品紹介 福徳寿
-------------------------------------*/
#PageItem.pageFukutokuju {
  #Main {
    a { color: #d35c2a; }
    #ContBox01 {
      .subBox {
        &_left {
          .trapezoid {
            &:before {
              border-bottom: solid #ea703d;
            }
            &:after {
              border-top: solid #ea703d;
            }
          }
          .txtList {
            ul {
              li {
                &:before {
                  background-color: #c75222;
                }
              }
            }
            .btn span {
              background-image: url(../img/contents/arrow_07.png);
            }
          }
        }
        &_right {
          background-image: url(../img/contents/top/bg_circle_03.png);
          .subBoxTxt {
            span {
              &:nth-of-type(1){
                background-image: url(../img/contents/top/vertxt_15.png);
              }
              &:nth-of-type(2){
                background-image: url(../img/contents/top/vertxt_03_03.png);
              }
              &:nth-of-type(3){
                background-image: url(../img/contents/top/vertxt_13.png);
              }
              &:nth-of-type(4){
                background-image: url(../img/contents/top/vertxt_07_02.png);
              }
            }
            p {
              background-image: url(../img/contents/top/vertxt_14.png);
            }
          }
          .subBoxPrice {
            li:first-of-type { margin-bottom: 0 !important; }
          }
        }
      }
      .subBoxImg span small { color: red; }
      .subBoxTable {
        th {
          background-image: url(../img/contents/bg_07.jpg);
          border: 1px solid #e1cec6;
        }
        td {
          border: 1px solid #e1cec6;
        }
      }
    }
    #ContBox02 {
      h3 {
        background-image: url(../img/contents/icon_13.png);
      }
    }
  }
}

/*
構成三本柱
-------------------------------------*/
#PageMaterial.pageIndex{
	#Main {
		.contBox {
			h2 {
				background-image: url("../img/contents/material/title.png");
			}
			img {
				width: 100%;
				height: auto;
			}
			.mainImg{
				background-color: #e7e9ed;
				background-size: auto 100%;
			}
		}
		#ContBox01{
			.contSubBox{
				margin: 0 auto;
				border-bottom: 1px solid #ddd;
				&:last-of-type{
					border-bottom: none;
				}
				.subBox{
					&_title{
						h3{
							position: relative;
							letter-spacing: 0.25em;
							&::after{
								content: "";
								position: absolute;
								left: 50%;
								display: block;
								width: 1px;
								height: 40px;
								background-color: #ddd;
							}
						}
					}
					&_cont{
						&_right{
							p{
								letter-spacing: .09em;
								&:last-of-type{
									margin-bottom: 0;
								}
							}
						}
						&_left{
							.subBoxTable {
								width: 100%;
								th {
									border: 1px solid #d1cbd6;
									letter-spacing: .1em;
									background: url(../img/contents/bg_03.jpg) top left;
									background-size: 50px auto;
									font-weight: 400;
								}
								td {
									border: 1px solid #d1cbd6;
									p{
										&:last-of-type{
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
贈答品として
-------------------------------------*/
#PageGifts.pageIndex{
	#Main {
		.contBox {
			h2 {
				background-image: url("../img/contents/gifts/title.png");
			}
			img {
				width: 100%;
				height: auto;
			}
			.mainImg{
				background-color: #d0c0a1;
				background-size: auto 100%;
			}
		}
		#ContBox01{
			.contSubBox{
				margin: 0 auto;
				.imgBox{
					a{
						position: relative;
						display: block;
						&::after{
							content: "";
							position: absolute;
							right: 0;
							bottom: 0;
							display: block;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url("../img/contents/icon_search.png");
 							background-color: #6a5180;
						}
					}
 				}
				.textBox{
					border-bottom: 1px solid #ddd;
					h3{
						background-repeat: no-repeat;
						background-position: center top;
						background-image: url("../img/contents/icon_01.png");
						letter-spacing: .2em;
						span{
							display: block;
						}
					}
					p{
						text-align: center;
						letter-spacing: .08em;
						&:last-of-type{
							margin-bottom: 0;
						}
					}
					.note{
						li{
							position: relative;
							text-align: justify;
							letter-spacing: 0;
							color: #888;
							&::before{
								content: "※";
								position: absolute;
								top: 0;
								left: 0;
							}
						}
					}
 				}
			}
		}
	}
}

/*
村井について
-------------------------------------*/
#PageAbout.pageIndex {
  #Main {
    #ContBox01 {
      h2 { background-image: url(../img/contents/about/title.png); }
      .mainImg {
        background-image: url(../img/contents/about/mainimage.jpg);
        background-size: cover;
      }
      h3 {
        display: inline-block;
        width: auto;
        height: auto;
        text-align: center;
        background: url(../img/contents/icon_01.png) no-repeat top center;
        margin: 0 auto;
        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 40px;
          background: #7f718b;
          margin: 0 auto;
          margin-top: 7px;
        }
      }
      .box01 {
        .boxCont {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          background-repeat: no-repeat;
        }
        .right {
          color: #fff;
          ul{
            li {
             &:last-of-type { margin-bottom: 0; }
            }
          }
          b{
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("../img/contents/top/tenshu_txt_02.png");
            text-indent: -9999px;
          }
        }
      }
      .box02 {
        width: 100%;
        position: relative;
        margin-top: -2px;
        .boxCont01 {
          .boxInner {
            color: #fff;
            h3 {
              background-image: url(../img/contents/icon_02.png);
              position: relative;
            }
            ul {
              margin-top: 10px;
              text-align: left;
              li {
                line-height: 1.75;
                letter-spacing: .1em;
                margin-bottom: 23px;
                padding-left: 30px;
                position: relative;
                &::before {
                  content: '一、';
                  display: inline-block;
                  position: absolute;
                  left: 0;
                }
              }
              li:last-child {
                font-size: 12px;
                margin-bottom: 26px;
                &::before { display: none; }
              }
            }
            small { font-size: 12px; letter-spacing: .1em }
          }
        }
        .boxCont02 {
          p {
            text-align: center;
            letter-spacing: .11em;
          }
        }
      }
      .box03 {
        .boxCont {
          display: flex;
          .imageBox {
            img {
              width: 100%;
              height: auto;
            }
          }
          .textBox {
            dl {
              display: flex;
              text-align: justify;
              font-size: 14px;
              letter-spacing: 0.1em;
              line-height: 24px;
              border-top: 1px solid #ddd;
              &:last-of-type {
                border-bottom: 1px solid #ddd;
              }
            }
          }
        }
      }
    }
  }
}

/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Header {
      .import {
        height: 50px;
        background-position: center right 30px;
        background-size: 9px auto;
        .import_in {
          padding-left: 30px;
          time {
            font-size: 14px;
            margin-right: 23px;
            letter-spacing: .17em;
          }
          p {
            width: calc(100% - 230px);
            line-height: 50px;
          }
        }
      }
      #MainImg {
        display: block;
        width: 100%;
        height: 800px;
        overflow: hidden;
        .mainImg_slide {
          position: relative;
          width: 100%;
          min-width: 1280px;
          height: 100%;
          left: 50%;
          transform: translate(-50%);
          &_item {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
          }
          &_item01 {
            background-image: url(../img/header/mainimg_PC_01.jpg);
          }
          &_item02 {
            background-image: url(../img/header/mainimg_PC_10.jpg);
            .text {
              width: 106px;
              height: 198px;
              background-size: 106px auto;
              right: 60px;
              bottom: 176px;
            }
          }
          &_item03 {
            background-image: url(../img/header/mainimg_PC_09.jpg);
      			background-position: center top;
          }
        }
      }
    }
    #Main {
      #ContBox00 {
        padding: 90px 0;
        .bakedBox {
          &_in {
            margin: 0 auto;
            width: 1000px;
            #BusinessHours {
              width: 50%;
              margin-left: 30px;
            }
          }
          &_cont {
            display: flex;
            justify-content: space-between;
            padding: 24px 38px;
            .trapezoid {
              width: 301px;
              height: 40px;
              font-size: 16px;
              letter-spacing: 0.3em;
              line-height: 40px;
              padding-left: 7px;
              top: -42px;
              &:before, &:after {
                width: calc(100% - 26px);
                border-right-width: 13px;
                border-left-width: 13px;
              }
              &:before {
                border-bottom-width: 20px;
              }
              &:after {
                border-top-width: 20px;
              }
            }
          }
          &_left {
            // width: calc(100% - 254px);
            width: 100%;
            letter-spacing: .08em;
            p { line-height: 2; }
            ul {
              margin-bottom: 2px;
              li {
                font-size: 16px;
                line-height: 2;
              }
            }
            strong {
              font-size: 14px;
              color: #888;
              line-height: 24px;
            }
          }
          /* &_right {
            width: 254px;
            img {
              width: 100%;
              height: auto;
            }
          } */
        }
      }
      #ContBox01 {
        h2 {
          margin-bottom: 0;
        }
        .contSubBox {
          width: 1000px;
          margin: 0 auto;
          h3 {
            background-position: center 49.5% top;
            background-size: 17px 17px;
            font-size: 20px;
            letter-spacing: 0.25em;
            line-height: 1.65;
            padding: 30px 0 15px;
            margin-bottom: 30px;
            &:after {
              transform: translateY(15px);
            }
          }
        }
        .subBox {
          width: 50%;
          .subBoxCont {
            height: 413px;
            background-size: 370px auto;
            background-position: left 2px top 37px;
          }
          .subBoxImg {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            a {
              display: block;
              width: 100%;
              height: 100%;
            }
            img {
              position: relative;
              width: 100%;
              height: 100%;
            }
          }
          .subBoxTxt {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            text-align: right;
            span {
              width: 16px;
              background-size: 16px auto;
              position: absolute;
            }
            p { position: absolute; }
          }
          .subBoxBtn {
    			  padding-right: 17px;
            width: 390px;
            height: 50px;
            font-size: 14px;
            letter-spacing: 0.3em;
            line-height: 30px;
    			  &::after{
    				  top: 10px;
    				  right: 29%;
    			  }
          }
        }
        .contSubBox01 {
          // display: flex;
          // flex-wrap: wrap;
          padding-bottom: 60px;
          .subBox01 {
            width: 100%;
            position: relative;
            .subBoxCont {
              height: 470px;
              margin: 45px 0 40px;
              padding-top: 95px;
              background-size: 463px auto;
              background-position: left 267px top;
            }
            .subBoxImg {
              width: 353px;
              height: 310px;
            }
            .subBoxTxt {
              width: 100%;
              span {
                width: 20px;
                background-size: 20px auto;
                &:nth-of-type(1) {
                  width: 17px;
                  height: 226px;
                  background-size: 17px auto;
                  left: 126px;
                  bottom: 90px;
                }
                &:nth-of-type(2) {
                  height: 236px;
                  top: 71px;
                  right: 192px;
                }
                &:nth-of-type(3) {
                  height: 260px;
                  top: 71px;
                  right: 152px;
                }
                &:nth-of-type(4) {
                  height: 97px;
                  top: 45px;
                  right: 112px;
                }
              }
              p {
                width: 49px;
                height: 310px;
                background-size: 49px auto;
                left: 159px;
                bottom: 72px;
                margin-bottom: 0;
              }
            }
            .subBoxBtn {
              border: 10px solid;
              border-image: url(../img/contents/frame_05_pc.png) 10 round;
              transform: translateX(-15px);
              transition: .3s linear;
              &:hover {
                opacity: 1;
                background-color: #e6dfec;
              }
            }
          }
        }
        .contSubBox02 {
          width: 100%;
          padding-top: 60px;
          padding-bottom: 120px;
          .subBox {
            width: 1000px;
            margin: 0 auto;
            .subBoxCont {
              height: auto;
              position: relative;
            }
            .subBoxTxt {
              width: 498px;
              height: 320px;
              top: 24px;
              left: 50%;
              transform: translateX(-50%);
              span {
                width: 16px;
                &:nth-of-type(1) {
                  width: 14px;
                  height: 98px;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
                  top: 19px;
                }
                &:nth-of-type(2) {
                  height: 189px;
                  right: 98px;
                }
                &:nth-of-type(3) {
                  right: 64px;
                }
                &:nth-of-type(4) {
                  height: 211px;
                  right: 32px;
                }
                &:nth-of-type(5) {
                  height: 246px;
                  right: 0;
                }
              }
              p {
                width: 85px;
                background-size: 100% auto;
                left: 26px;
                bottom: 0;
                margin-bottom: 0;
              }
            }
            .subBoxImg {
              width: 370px;
              height: 376px;
              a {
                width: 227px;
                height: 234px;
              }
            }
            .subBoxDetail {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              pointer-events: none;
              .item {
                position: absolute;
                width: 190px;
                flex-wrap: wrap;
                dt {
                  width: 100%;
                  img {
                    width: 102px;
                    height: auto;
                  }
                }
                dd {
                  width: 100%;
                  padding-top: 11px;
                  p {
                    text-align: center;
                    &.gray {
                      font-size: 12px;
                      letter-spacing: 0.1em;
                      line-height: 24px;
                      margin-top: 2px;
                    }
                    span {
                      display: block;
                      font-size: 12px;
                      line-height: 1;
                      margin-top: 3px;
                      margin-bottom: 4px;
                    }
                  }
                }
              }
            }
            .subBoxBtn {
              &::after { right: 79px; }
            }
          }
          .subBox01 {
            margin-bottom: 80px;
            .subBoxCont { margin: 261px 0 317px; }
            .subBoxTxt {
              span {
                &:nth-of-type(3) {
                  height: 165px;
                }
              }
              p { height: 253px; }
            }
            .subBoxDetail {
              height: calc(100% + 524px);
              top: -261px;
              .item01 {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
              }
              .item02 {
                top: 80px;
                right: 185px;
              }
              .item03 {
                top: 250px;
                right: 15px;
              }
              .item04 {
                right: 15px;
                bottom: 250px;
              }
              .item05 {
                right: 185px;
                bottom: 80px;
              }
              .item06 {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
              .item07 {
                left: 185px;
                bottom: 80px;
              }
              .item08 {
                left: 15px;
                bottom: 250px;
              }
              .item09 {
                top: 250px;
                left: 15px;
              }
              .item10 {
                top: 80px;
                left: 185px;
              }
            }
            .subBoxBtn {
              border: 10px solid;
              border-image: url(../img/contents/frame_09_02_pc.png) 10 round;
            }
          }
          .subBox02 {
            .subBoxCont { margin-bottom: 57px; }
            .subBoxTxt {
              span {
                &:nth-of-type(3) {
                  height: 218px;
                }
              }
              p { height: 255px; }
            }
            .subBoxImg {
              span { bottom: 29px; }
            }
            .subBoxDetail {
              height: 400px;
              top: 7px;
              .item01 {
                top: 0;
                left: 46px;
              }
              .item02 {
                top: 0;
                right: 45px;
              }
              .item03 {
                left: 46px;
                bottom: 0;
              }
              .item04 {
                right: 45px;
                bottom: 0;
              }
            }
            .subBoxBtn {
              border: 10px solid;
              border-image: url(../img/contents/frame_06_02_pc.png) 10 round;
            }
          }
          .btnArea {
            margin-top: 90px;
            a {
              width: 470px;
              height: 89px;
              line-height: 69px;
              padding-right: 50px;
              border: 10px solid;
              span {
                font-size: 14px;
                &:after {
                  width: 11px;
                  height: 10px;
                  top: 30px;
                }
              }
              &:nth-of-type(1) {
                border-image: url(../img/contents/frame_06_02_pc.png) 10 round;
                span {
                  padding: 0 19px 0 90px;
                  background-position: left top 1px;
                  background-size: 76px auto;
                }
              }
              &:nth-of-type(2) {
                border-image: url(../img/contents/frame_09_02_pc.png) 10 round;
                margin-left: 60px;
                span {
                  padding: 0 19px 0 107px;
                  background-position: left top;
                  background-size: 74px auto;
                }
              }
            }
          }
        }
        .contSubBox03 {
          width: 100%;
          .txtArea {
            padding: 60px 0 110px;
            .listWrap {
              width: 1000px;
              margin: 0 auto;
              .listItem {
                margin-top: 45px;
                width: calc(900px / 3);
                .trapezoid {
                  width: 156px;
                  height: 24px;
                  font-size: 12px;
                  letter-spacing: .2em;
                  line-height: 24px;
                  padding-left: 2px;
                  &:before, &:after {
                    width: calc(100% - 16px);
                    border-right: 8px solid transparent;
                    border-left: 8px solid transparent;
                  }
                  &:before {
                    border-bottom: 12px solid #4a365b;
                  }
                  &:after {
                    border-top: 12px solid #4a365b;
                  }
                }
                p {
                  font-size: 14px;
                  line-height: 1.75;
                  letter-spacing: .1em;
                  margin-top: 10px;
                }
                &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3){
                  margin-top: 0;
                }
                &:nth-of-type(3) {
                  .trapezoid { width: 166px; }
                }
              }
            }
          }
        }
        .contSubBox04 {
          .subBoxWrap {
            width: 1000px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            padding: 76px 0 60px;
          }
          .subBox {
            width: 296px;
            .subBoxCont {
              height: auto;
              background-position: center top;
              background-repeat: no-repeat;
              background-size: 296px auto;
            }
            .subBoxTxt {
              position: relative;
              width: 228px;
              height: 230px;
              p {
                width: 39px;
                height: 230px;
                top: 0;
                left: 82px;
              }
              span {
                &:nth-of-type(1) {
                  width: 70px;
                  background-size: 100% auto;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(2) {
                  height: 189px;
                  right: 64px;
                }
                &:nth-of-type(3) {
                  right: 32px;
                }
                &:nth-of-type(4) {
                  height: 246px;
                  top: -16px;
                  right: 0;
                }
              }
            }
            .subBoxItem {
              display: flex;
              justify-content: center;
              height: 70px;
              img {
                width: 70px;
                height: auto;
              }
            }
            .subBoxBtn {
              display: none;
              width: 390px;
              height: 50px;
              border: 10px solid;
              margin-top: 20px;
            }
          }
          .subBox01 {
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_02_02.png);
            }
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 146px; }
                &:nth-of-type(3) { height: 208px; }
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_06_02_pc.png) 10 round;
              color: #b0640c;
              &:after {
                background-image: url(../img/contents/arrow_06.png);
                right: 51px;
              }
              &:hover {
                background-color: #f4ede5;
                opacity: 1;
              }
            }
          }
          .subBox02 {
            margin-left: 94px;
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_03_02.png);
            }
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 139px; }
                &:nth-of-type(3) { height: 187px; }
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_09_02_pc.png) 10 round;
              color: #d35c2a;
              &:after {
                background-image: url(../img/contents/arrow_07.png);
                right: 60px;
              }
              &:hover {
                background-color: #f5ebe6;
                opacity: 1;
              }
              &.sp_display { display: none; }
            }
          }
        }
        .contSubBox05 {
          width: 100%;
          padding: 45px 0 60px;
          .subBox {
            width: 500px;
            .subBoxCont {
              background-size: 370px auto;
              padding: 80px 0 68px;
            }
            .subBoxTxt {
              p {
                width: 22px;
                height: 176px;
                top: 106px;
                left: 62px;
              }
              span {
                width: 16px;
                height: 55px;
                top: 230px;
                left: 35px;
              }
            }
            .subBoxImg {
              a {
                width: 270px;
                height: 239px;
                margin: 0 auto;
              }
            }
            .subBoxBtn {
              width: 390px;
              height: 50px;
              color: #e60012;
              border: 10px solid;
              border-image: url(../img/contents/frame_10_pc.png) 10 round;
              margin-top: 8px;
              &:hover {
                background-color: #f4e3e4;
                opacity: 1;
              }
              &:after {
                background-image: url(../img/contents/arrow_08.png);
                right: 86px;
              }
            }
          }
        }
      }
      #ContBox02 {
        h2 { margin-bottom: 80px; }
        .contSubBox {
          .click { pointer-events: none; }
          .clickArea {
            height: 360px;
            overflow: hidden;
            .clickBg {
              min-width: 1280px;
              height: 360px;
              position: relative;
              background-position: center;
              background-size: cover;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0, .1);
              }
            }
            .btnTxt {
              top: 50%;
              transform: translateY(-50%);
              h3 {
                font-size: 36px;
                letter-spacing: .3em;
                display: inline-block;
                transform: translate(6.5px, 2px);
              }
              span { display: none; }
            }
          }
          .subBoxInner {
            width: 1000px;
            margin: 0 auto;
            padding-top: 60px;
            h4 {
              font-size: 20px;
              line-height: 1.65;
              letter-spacing: 0.25em;
              background-size: 17px 17px;
              transform: translateX(1px);
              background-position: top left 49.5%;
              padding: 30px 0 15px;
              margin-bottom: 30px;
              &:after {
                height: 40px;
                transform: translateY(15px);
              }
            }
          }
        }
        .contSubBox01 {
          padding-bottom: 89px;
          .clickBg { background-image: url(../img/contents/pc/img_01_02.jpg); }
          .subBoxInner {
            .boxCont {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin-bottom: 60px;
              .boxImg {
                width: 50%;
                img { width: 500px; }
              }
              .boxTxt {
                width: 44%;
                overflow: hidden;
                a {
                  height: 89px;
                  letter-spacing: 0.1em;
                  line-height: 65px;
                  padding-left: 104px;
                  border: 12px solid;
                  border-image: url(../img/contents/frame_07_pc.png) 12 round;
                  &:before {
                    width: 63px;
                    height: calc(100% + 22px);
                    left: 19px;
                    top: -11px;
                  }
                  &:nth-of-type(1):before {
                    background-size: 30px auto;
                  }
                  &:nth-of-type(2):before {
                    background-size: 54px auto;
                  }
                  &:nth-of-type(3):before {
                    background-size: 63px auto;
                  }
                  & + a {
                    margin-top: 20px;
                  }
                }
              }
            }
            .boxBtn {
              width: 390px;
              height: 50px;
              font-size: 14px;
              letter-spacing: 0.3em;
              line-height: 32px;
              text-decoration: none;
              border: 10px solid;
              border-image: url(../img/contents/frame_05_02_pc.png) 10 round;
              transition: .3s linear;
              span {
                padding-right: 15.8px;
                background: url(../img/contents/arrow_03.png) no-repeat right top 3px;
                background-size: 11px auto;
              }
              &:hover {
                opacity: 1;
                background-color: #e6dfec;
              }
            }
          }
        }
        .contSubBox02 {
          .clickBg { background-image: url(../img/contents/pc/img_02.jpg); }
          .subBoxInner {
            width: 100%;
            h4 {
              transform: translateX(3px);
              background-position: top left 48%;
              margin-bottom: 31px;
              &:after { transform: translate(-3px, 15px); }
            }
            .box01 {
              width: 1000px;
              margin: 0 auto;
              padding-bottom: 90px;
              .boxCont {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
      				  margin-bottom: 50px;
                .left {
                  width: 50%;
                  img { width: 100%; }
                  p {
                    font-size: 16px;
                    line-height: 2;
                    margin-top: 23px;
                  }
                }
                .right {
                  width: 44%;
                  height: 499px;
                  background-size: 440px auto;
                  padding: 54px 60px;
                  h5 {
                    font-size: 20px;
                    line-height: 1.6;
                    margin-bottom: 24px;
                  }
                  p {
                    line-height: 1.7;
                    letter-spacing: .1em;
                    margin-top: 13px;
                  }
                }
              }
      				> p{
      					clear: both;
      					margin-bottom: 21px;
      					line-height: 32px;
      					text-align: center;
      					letter-spacing: .09em;
      					font-size: 16px;
      				}
      				.boxBtn {
      				  width: 390px;
      				  height: 50px;
      				  font-size: 14px;
      				  letter-spacing: 0.3em;
      				  line-height: 32px;
      				  text-decoration: none;
      				  border: 10px solid;
      				  border-image: url(../img/contents/frame_05_02_pc.png) 10 round;
                transition: .3s linear;
      				  span {
        					padding-right: 15.8px;
        					background: url(../img/contents/arrow_03.png) no-repeat right top 3px;
        					background-size: 11px auto;
      				  }
                &:hover {
                  opacity: 1;
                  background-color: #e6dfec;
                }
      				}
            }
          }
        }
      }
      #ContBox03 {
        padding-bottom: 150px;
        h2 { margin-bottom: 39px; }
        .leaderText {
          margin-bottom: 60px;
          & > p {
            font-size: 16px;
            line-height: 32px;
          }
          & > span {
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 30px;
          }
          .btnArea { margin-top: 4px; }
        }
        .contSubBox {
          width: 1000px;
          margin: 0 auto;
          display: grid;
          grid-template-rows: 171px 255px;
          grid-column-gap: 60px
        }
        .boxImage {
          grid-row: 1 / 3;
          padding-top: 30px;
          position: relative;
          .shopImage {
            width: 100%;
            max-width: 640px;
            height: 396px;
            background: url(../img/contents/top/img_08_01.jpg) no-repeat;
            background-size: cover;
          }
          .verTxt {
            position: absolute;
            top: 16px;
            left: 30px;
            width: 40px;
            font-size: 16px;
            color: #fff;
            letter-spacing: 0.3em;
            line-height: 40px;
            text-align: center;
            writing-mode: vertical-lr;
            background-color: #4a365b;
            padding: 9px 0 5px;
            &:before, &:after {
              position: absolute;
              content: '';
              left: 0;
              width: 100%;
              height: 16px;
              background-repeat: no-repeat;
              background-position: left top;
              background-size: 100% auto;
            }
            &:before {
              top: -16px;
              background-image: url(../img/contents/top/bg_txt_02_01.png);
            }
            &:after {
              bottom: -16px;
              background-image: url(../img/contents/top/bg_txt_02_02.png);
            }
          }
        }
        .boxTxt {
          p {
            line-height: 2;
            margin-bottom: 0;
            position: relative;
            top: 50%;
            transform: translateY(-36%);
            span:after {
              width: 12px;
              height: 9px;
              background-size: 12px auto;
              left: 5px;
            }
          }
        }
        .boxTable {
          tr {
            font-size: 14px;
            line-height: 1;
            td {
              // padding: 16px 0 19px;
              padding-top: 27px;
              line-height: 24px;
              a { font-size: 16px; }
            }
            &.link {
              border-bottom-width: 0;
              td {
                padding: 30px 0 26px;
                a {
                  display: none;
                  padding: 4px 0 5px;
                  border: 10px solid;
                  border-image: url(../img/contents/frame_05_02_pc.png) 10 round;
                  transition: 0.5s ease;
                }
              }
            }
          }
        }
        .contSubBox01 {
          grid-template-columns: 64% 30%;
          .boxImage {
            grid-column: 1 / 2;
          }
        }
        .contSubBox02 {
          grid-template-columns: 30% 64%;
          margin-top: 60px;
          .boxImage {
            grid-column: 2 / 2;
            .shopImage {
              background-image: url(../img/contents/top/img_08_02.jpg);
            }
          }
        }
      }
      #ContBox04 {
        h2 {
          background-position: center bottom 1px;
          margin-bottom: 60px;
        }
        .listBoxArea {
          overflow: hidden;
          position: relative;
          .swiper-wrapper {
            width: 1000px;
            margin: 0 auto;
            .listBox {
              .labelLine { padding-left: 8px; }
            }
          }
        }
        .buttonArea {
          position: relative;
          width: 1000px;
          height: 60px;
          margin: 0 auto;
        }
        .swiper-button {
          &-prev,
          &-next {
            width: 50px;
            height: 50px;
            transform: rotate(45deg);
            background-color: #4a365b;
            background-image: none;
            top: 22px;
            transition: background-color .3s linear;
            &:after {
              font-size: 0;
              width: 13px;
              height: 12px;
              background-repeat: no-repeat;
              background-size: 13px auto;
              background-position: top left;
            }
            &:hover { background-color: #371e4c; }
          }
          &-prev {
            left: 11px;
            &:after {
              background-image: url(../img/contents/arrow_01.png);
              transform: rotate(45deg);
            }
          }
          &-next {
            right: 11px;
            &:after {
              background-image: url(../img/contents/arrow_02.png);
              transform: rotate(-45deg);
            }
          }
          &-disabled { opacity: 0; }
        }
        .bottomImg {
          height: 250px;
          background-image: url(../img/contents/pc/img_05.jpg);
          margin-top: 150px;
        }
      }
      #ContBox05 {
        h2 { margin-bottom: 73px; }
        .contSubBox01 {
          width: 1000px;
          margin: 0 auto;
        }
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main {
      #ContBox01 {
        h2 {
          margin-bottom: 74px;
          background-position: center bottom;
        }
        .txtWrong {
          p {
            font-size: 16px;
            line-height: 2;
            letter-spacing: .09em;
            text-align: center;
          }
        }
        #ContSubBox01 {
          width: 1000px;
          margin: 0 auto;
          .txtArea {
            margin-bottom: 80px;
            p {
              font-size: 16px;
              line-height: 2;
              text-align: center;
              letter-spacing: .09em;
            }
          }
          .formArea {
            h3 {
              height: 61px;
              line-height: 61px;
              font-size: 20px;
              letter-spacing: .25em;
              margin-bottom: 59px;
              span {
                display: inline-block;
                transform: translateX(3px);
              }
            }
            .formInner {
              display: flex;
              flex-wrap: wrap;
              th {
                font-size: 16px;
                height: 24px;
                line-height: 1.5;
                &:before {
                  width: 40px;
                  height: 20px;
                  line-height: 20px;
                  font-size: 12px;
                  transform: translateY(-2px);
                }
              }
              td {
                font-size: 16px;
                line-height: 1.5;
                margin: 22px 0 40px;
              }
              .right {
                width: 50%;
              }
              .left {
                width: 50%;
                padding-left: 15px;
              }
            }
          }
        }
        .sendBtn { margin: 15px 0 26px; }
        .txtBtn {
          font-size: 14px;
          margin-bottom: 166px;
        }
      }
    }
  }

  /*
  送信完了画面
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main {
      #ContBox01 {
        h2 {
          background-position: center bottom;
          margin-bottom: 73px;
        }
        #ContSubBox01 {
          text-align: center;
          .txtArea {
            p {
              font-size: 16px;
              line-height: 2;
              letter-spacing: .09em;
              text-align: center;
            }
          }
          .telBtn { margin: 22px 0 54px; }
          .txtBtn {
            font-size: 14px;
            margin-bottom: 166px;
          }
        }
      }
    }
  }

  /*
  新着情報
  -------------------------------------*/
  #PageNews.pageIndex {}

  /*
  新着情報 カテゴリー
  -------------------------------------*/
  #PageNews.pageCategory {
    #Main {
      #ContBox01 {
        .listMenu {
          .current-cat {
            a::after { content: none; }
            &::after {
              height: 91px;
              background: #ddd;
              position: absolute;
              top: 35px;
              left: 50%;
            }
          }
        }
      }
    }
  }

  /*
  新着情報 記事詳細
  -------------------------------------*/
  #PageNews.pageEntry {
    #Main {
      #ContBox01 {
        h2 { margin-bottom: 104px; }
        padding-bottom: 0px;
        .contSubBox {
          width: 600px;
          margin: 0 auto;
          h3 {
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: .1em;
            margin-bottom: 12px;
          }
          time {
            font-size: 14px;
            letter-spacing: .18em;
            margin-right: 14px;
          }
          .label {
            height: 22px;
            font-size: 12px;
            letter-spacing: .2em;
            line-height: 20px;
            text-indent: .2em;
            padding: 0 3px;
            margin-right: 16px;
            &:before, &:after {
              width: 8px;
              height: 22px;
              background-size: auto 22px;
            }
            &::before { left: -7px; }
            &::after { right: -7px; }
          }
          .entryBox {
            border-top: 1px solid #ddd;
            margin-top: 55px;
            padding: 53px 0 90px;
            p {
              line-height: 2;
              margin-bottom: 32px;
              &:last-of-type { margin-bottom: 0; }
            }
            a:hover { text-decoration: none !important; }
            .figerBox { margin-top: 52px; }
          }
        }
        .linkArea {
          width: 600px;
          height: 21px;
          margin: 58px auto 200px;
          li {
            a {
              font-size: 14px;
              letter-spacing: .3em;
              padding: 0 20px;
              background-size: 10px auto;
            }
            &.top {
              left: 50.3%;
              transform: translateX(-50%);
            }
            &.prev {
              left: 0;
            }
            &.next {
              right: 0;
              a { padding-right: 15px; }
            }
          }
        }
      }
    }
  }

  /*
  商品のご予約
  -------------------------------------*/
  #PageReservation.pageIndex,
  #PageYukigaya.pageIndex {
    #Main {
      #ContBox01 {
        h2 {
          height: 298px;
          background-size: 30px auto;
          margin-bottom: 72px;
        }
        .trapezoid {
          position: absolute;
          width: 111px;
          height: 29px;
          font-size: 12px;
          line-height: 30px;
          letter-spacing: .4em;
          padding-left: .4em;
          top: 16px;
          &:before, &:after {
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            width: calc(100% - 20px);
          }
          &:before { border-bottom: 15px solid #4a365b; }
          &:after { border-top: 15px solid #4a365b; }
        }
        .mainImg {
          margin-bottom: 60px;
          .mainImgInner {
            p {
              width: 40px;
              font-size: 16px;
              line-height: 40px;
              padding: 9px 0 5px;
              top: -14px;
              &:before {
                height: 16px;
                background-image: url(../img/contents/top/bg_txt_02_01.png);
                top: -16px;
              }
              &:after {
                height: 16px;
                background-image: url(../img/contents/top/bg_txt_02_02.png);
                bottom: -16px;
              }
            }
          }
        }
        #ContSubBox01 {
          width: 1000px;
          margin: 0 auto;
          .txtArea {
            h3 {
              background-size: 16px auto;
              padding-top: 34px;
            }
            & > p {
              margin-bottom: 32px;
              &:last-of-type { margin-bottom: 50px; }
            }
            .table {
              margin-bottom: 46px;
              h4 {
                top: 0;
                left: 0;
              }
              padding-left: 170px;
              table {
                &.pc_display { display: inline-table; }
                margin-bottom: 15px;
                tr {
                  &:first-of-type th { text-align: center; }
                }
                th {
                  padding: 13px 20px 14px;
                  text-align: left;
                }
                td {
                  width: 36%;
                  padding: 14px 0 13px;
                }
              }
            }
            .termTable {
              table {
                td {
                  width: 72%;
                  text-align: left;
                  padding: 13px 20px 14px;
                }
                tr:first-of-type {
                  th { text-align: left; }
                }
              }
            }
          }
          .formArea {
            margin-top: 82px;
            form { margin-top: 44px; }
            .formPart {
              .formInner {
                width: 100%;
                padding-left: 170px;
                tbody { width: 100%; }
                tr {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: top;
                  width: 100%;
                }
                th {
                  width: 230px;
                  padding-top: 18px;
                  padding-left: 50px;
                  font-weight: normal;
                  span { top: 23px; }
                }
                td {
                  width: calc(100% - 230px);
                  margin-bottom: 18px;
                  .radio {
                    width: 50px;
                    height: 50px;
                    margin-right: 9px;
                    border-radius: 50%;
                    box-shadow: none;
                    span { background-size: 50px 50px; }
                  }
                  .bag {
                    input[type="number"] {
                      width: 180px;
                      margin: 0 9px 0 26px;
                    }
                  }
                  input#Date { cursor: default; }
                  small {
                    font-size: 14px;
                    line-height: 1.7;
                    margin-top: 1px;
                    &:first-of-type { margin-top: 6px; }
                  }
                  textarea {
                    height: 290px;
                    letter-spacing: .09em;
                    padding: 13px 14px;
                  }
                }
              }
            }
            .formPart01 {
              padding-bottom: 42px;
              border-bottom: 1px solid #ddd;
              .formInner {
                tr {
                  &:nth-of-type(1) { margin: 1px 0 6px; }
                  &:nth-of-type(3) {
                    margin-top: 10px;
                    th {
                      padding-top: 4px;
                      span { top: 9px; }
                    }
                    td {
                      & > p {
                        font-size: 14px;
                        line-height: 24px;
                      }
                      small {
                        margin-top: 0;
                        line-height: 24px;
                        &:last-of-type { margin-bottom: 23px; }
                      }
                      dl {
                        padding-left: 28px;
                        dt {
                          margin-bottom: 6px;
                          &:before {
                            width: 17px;
                            height: 17px;
                            background-size: 17px auto;
                            top: 3px;
                            left: -28px;
                          }
                        }
                        dd {
                          .option {
                            &_l { margin-right: 19px; }
                          }
                          p { display: inline-block; }
                          .selector {
                            width: 120px !important;
                            height: 50px;
                          }
                        }
                        & + dl { margin-top: 26px; }
                      }
                    }
                  }
                  &:nth-of-type(4) {
                    th { padding-right: 10px; }
                    td {
                      padding-top: 12px;
                      p {
                        font-size: 14px;
                        margin-bottom: 10px;
                      }
                      textarea { height: 100px; }
                    }
                  }
                  &:nth-of-type(5) {
                    th {
                      position: relative;
                      padding-left: 50px;
                    }
                  }
                  &:last-of-type {
                    td {
                      padding-top: 12px;
                      p {
                        font-size: 14px;
                        margin-bottom: 10px;
                      }
                      textarea { height: 200px; }
                    }
                  }
                }
              }
            }
            .formPart02 {
              margin-top: 48px;
              .trapezoid { top: 22px; }
              tr { margin: 6px 0 6px; }
            }
            .privacy { margin-top: 29px; }
            .sendBtn {
              margin-bottom: 136px;
              p { transform: translateX(2px); }
            }
          }
        }
      }
    }
  }

  /*
  商品のご予約 確認画面
  -------------------------------------*/
  #PageReservation.pageConfirm,
  #PageYukigaya.pageConfirm {
    #Main {
      #ContBox01 {
        h2 {
          height: 358px;
          background-size: 30px auto;
          margin-bottom: 82px;
          transform: translateY(2px);
        }
        .txtWrong {
          p {
            font-size: 16px;
            line-height: 2;
            letter-spacing: .09em;
            text-align: center;
          }
        }
        #ContSubBox01 {
          width: 1000px;
          margin: 0 auto;
          .txtArea {
            margin-bottom: 81px;
            p {
              font-size: 16px;
              line-height: 2;
              text-align: center;
              letter-spacing: .09em;
            }
          }
          .formArea {
            h3 {
              height: 61px;
              line-height: 61px;
              font-size: 20px;
              letter-spacing: .25em;
              margin-bottom: 60px;
              span {
                display: inline-block;
                transform: translateX(3px);
              }
            }
            .formPart {
              position: relative;
              .trapezoid {
                position: absolute;
                width: 111px;
                height: 29px;
                font-size: 12px;
                line-height: 30px;
                letter-spacing: .4em;
                padding-left: .4em;
                top: 0;
                &:before, &:after {
                  border-right: 10px solid transparent;
                  border-left: 10px solid transparent;
                  width: calc(100% - 20px);
                }
                &:before { border-bottom: 15px solid #4a365b; }
                &:after { border-top: 15px solid #4a365b; }
              }
              .formInner {
                width: 100%;
                padding-left: 170px;
                display: flex;
                flex-wrap: wrap;
                tbody { width: 100%; }
                tr {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: top;
                  margin: 4px 0 26px;
                }
                th {
                  width: 230px;
                  font-size: 16px;
                  height: auto;
                  line-height: 1.5;
                  font-weight: normal;
                  padding-left: 50px;
                  &:before {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                  }
                }
                td {
                  width: calc(100% - 230px);
                  font-size: 16px;
                  line-height: 1.5;
                }
                .right {
                  width: 50%;
                }
                .left {
                  width: 50%;
                  padding-left: 15px;
                }
              }
            }
            .formPart01 {
              padding-bottom: 31px;
              border-bottom: 1px solid #ddd;
              .formInner {
                tr:nth-of-type(3) {
                  th {
                    height: auto;
                    position: relative;
                    padding-left: 50px;
                    &:before {
                      position: absolute;
                      left: 0;
                      transform: translateY(1px);
                    }
                  }
                  td {
                    dl {
                      padding-left: 28px;
                      dt {
                        margin-bottom: 6px;
                        &:before {
                          width: 17px;
                          height: 17px;
                          background-size: 17px auto;
                          top: 3px;
                          left: -28px;
                        }
                      }
                      dd {
                        display: flex;
                        p {
                          line-height: 1.5;
                          margin-bottom: 0;
                          & + p { margin-left: 20px; }
                        }
                      }
                      & + dl { margin-top: 15px; }
                    }
                  }
                }
              }
            }
            .formPart02 {
              margin-top: 70px;
              tr:last-of-type td { text-align: justify; }
            }
          }
        }
        .sendBtn {
          margin: 30px 0 26px;
          p {
            letter-spacing: .2em;
            padding-left: .2em;
          }
        }
        .txtBtn {
          font-size: 14px;
          margin-bottom: 177px;
        }
      }
    }
  }

  /*
  商品のご予約 送信完了画面
  -------------------------------------*/
  #PageReservation.pageThanks,
  #PageYukigaya.pageThanks {
    #Main {
      #ContBox01 {
        h2 {
          height: 358px;
          background-size: 30px auto;
          margin-bottom: 82px;
          transform: translateY(2px);
        }
        #ContSubBox01 {
          text-align: center;
          .txtArea {
            p {
              font-size: 16px;
              line-height: 2;
              letter-spacing: .09em;
              text-align: center;
              margin-bottom: 30px;
            }
          }
          .telBtn { margin: 22px 0 54px; }
          .txtBtn {
            font-size: 14px;
            margin-bottom: 185px;
          }
        }
      }
    }
  }

  /*
  商品のご注文
  -------------------------------------*/
  #PageOrder {
    #Main {
      #ContBox01 h2 {
        height: 353px;
        background-size: 30px auto;
      }
    }
  }
  #PageOrder.pageIndex {
    #Main {
      #ContBox01 {
        p {
          letter-spacing: .1em;
          line-height: 2;
        }
        .txtArea {
          width: 1000px;
          margin: 66px auto 0;
          p { margin-bottom: 16px; }
        }
        .pageNaviWrap {
          width: 1000px;
          margin: 61px auto 0;
          .pageNavi {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .navi {
              display: inline-block;
              a {
                display: inline-block;
                padding: 21px 12px 21px 13px;
                background-position: bottom center;
                background-size: 12px auto;
                img {
                  width: 17px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        .contSubBox {
          width: 1000px;
          margin: 0 auto;
          .boxTitle {
            font-size: 20px;
            height: 61px;
            line-height: 61px;
          }
          table.txtTable {
            font-size: 14px;
            line-height: 2;
            letter-spacing: .1em;
            th {
              padding: 12px 20px;
            }
            td {
              padding: 12px 20px;
            }
          }
          ul {
            li {
              font-size: 14px;
              line-height: 1.7;
            }
            &.noteLists { margin-top: 15px; }
          }
          .note { padding-left: 15px; }
          .kanji {
            padding-left: 40px;
            font-size: 16px;
            line-height: 2;
          }
          .num {
            padding-left: 25px;
            font-size: 16px;
            line-height: 2;
          }
          span.note {
            display: block;
            text-align: left;
            font-size: 14px;
            line-height: 1.7;
            margin-top: 17px;
          }
        }
        #ContSubBox01 {
          margin-top: 89px;
          .boxCont {
            margin-top: 50px;
            table {
              &:first-of-type {
                th { width: 240px; }
              }
              &.pc_table {
                margin-top: 30px;
                th {
                  width: 360px;
                  img { margin-top: 12px; }
                }
                td { vertical-align: middle; }
              }
            }
          }
        }
        #ContSubBox02 {
          margin-top: 85px;
          .boxCont {
            margin-top: 42px;
          }
          table {
            margin-top: 24px;
            th {
              width: 161px;
              vertical-align: middle;
            }
            td {
              width: 70px;
              letter-spacing: 0;
              text-align: center;
              padding: 12px 9px;
              small { font-size: 10px; margin-left: 1px;  }
            }
            tr:nth-of-type(1) {
              td {
                line-height: 1.5;
                vertical-align: middle;
              }
            }
            tr:nth-of-type(2) {
              td { font-size: 12px; }
            }
          }
        }
        #ContSubBox03 {
          margin-top: 83px;
          .boxCont {
            margin-top: 41px;
            .tableWrap {
              margin-top: 22px;
              table {
                th { width: 240px; }
              }
            }
          }
        }
        #ContSubBox04 {
          margin-top: 83px;
          overflow: auto;
          .boxLeft {
            float: left;
            width: 480px;
            p.foreword { margin-top: 41px; }
            .kanjiLists { margin-top: 32px; }
            p:last-of-type { margin: 32px 0 0; }
          }
          .boxRight {
            float: right;
            width: 480px;
            p:first-of-type { margin-top: 41px; }
            p { margin: 16px 0 0; }
          }
        }
        #ContSubBox05 {
          margin-top: 82px;
          .formArea {
            form { margin-top: 50px; }
            .formPart {
              margin-top: 60px;
              position: relative;
              border-bottom: 1px solid #ddd;
              &:first-of-type { margin-top: 0; }
              .trapezoid {
                position: absolute;
                width: 111px;
                height: 29px;
                font-size: 12px;
                line-height: 30px;
                letter-spacing: .4em;
                padding-left: .4em;
                top: 10px;
                &:before, &:after {
                  border-right: 10px solid transparent;
                  border-left: 10px solid transparent;
                  width: calc(100% - 20px);
                }
                &:before { border-bottom: 15px solid #4a365b; }
                &:after { border-top: 15px solid #4a365b; }
              }
              .formInner {
                width: 100%;
                padding-left: 170px;
                tbody { width: 100%; }
                tr {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  margin-bottom: 30px;
                  &:last-of-type { margin-bottom: 0; }
                  &.address {
                    margin-bottom: 25px;
                    th { height: auto; }
                    td:last-of-type {
                      margin-top: 6.23px;
                      transform: translateX(230px);
                    }
                  }
                }
                th {
                  width: 230px;
                  padding-top: 13px;
                }
                td {
                  width: calc(100% - 230px);
                  height: auto;
                  margin: 0;
                }
                label.radioBtn {
                  display: inline-block;
                }
                div.radio {
                  width: 50px;
                  height: 50px;
                  margin-right: 10px;
                  span.checked:before {
                    width: 16px;
                    height: 16px;
                  }
                }
                .noteLists, span.note { margin-top: 5px; }
              }
            }
            .formPart01 {
              padding-bottom: 55px;
              .formInner {
                tr:nth-of-type(2) {
                  margin-bottom: 24px;
                  td {
                    p { margin: 0; }
                    input {
                      width: 180px;
                      margin-right: 9px;
                    }
                  }
                }
                tr:nth-of-type(3) {
                  th {
                    position: relative;
                    padding-left: 50px;
                    span.any {
                      position: absolute;
                      top: 18px;
                      left: 0;
                    }
                  }
                  td {
                    input[type="number"] {
                      width: 180px;
                      margin: 0 9px 0 26px;
                    }
                  }
                }
              }
            }
            .formPart02 {
              padding-bottom: 55px;
              .trapezoid { width: 141px; }
              .formInner {
                tr:nth-of-type(1) { margin-bottom: 26px; }
                tr:nth-of-type(2) {
                  .radioBtn { width: 226px; }
                  .radioBtn:nth-of-type(n+3) { margin-top: 10px; }
                }
                tr:nth-of-type(3) { margin-bottom: 25px; }
                tr:last-of-type  td {
                    .selector select option:first-of-type { display: block; }
                }
              }
            }
            .formPart03 {
              padding-bottom: 53px;
              tr:last-of-type {
                td {
                  textarea {
                    width: 100%;
                    height: 200px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    padding: 15px;
                  }
                }
              }
            }
            .formPart04 {
              margin-top: 60px;
              border-bottom-width: 0;
              .trapezoid { width: 126px; }
              .formInner {
                tr:first-of-type {
                  margin-bottom: 21px;
                  td {
                    .radioBtn {
                      &:first-of-type { margin-right: 15px; }
                      .radio { top: -3px; }
                    }
                  }
                }
                tr.noteLine { margin-bottom: 24px; }
              }
            }
            .lawBoxWrap {
              margin-top: 60px;
              position: relative;
              .lawBoxTitle {
                padding: 10px 0 17px;
                &:after { top: 41px; }
              }
              .lawBoxCont {
                height: 560px;
                overflow-y: scroll;
                padding: 60px 0;
                position: relative;
                .contIn {
                  padding: 0 50px;
                  dl {
                    font-size: 14px;
                    padding: 16px 0 15px;
                    &:first-of-type { padding-top: 0; }
                    &:last-of-type { padding-bottom: 0; }
                  }
                  dt { width: 218px; }
                  dd {
                    width: calc(100% - 218px);
                    .kanji {
                      font-size: 14px;
                      line-height: 1.7;
                    }
                    p {
                      font-size: 14px;
                      line-height: 1.7;
                    }
                  }
                }
              }
              .lawBoxCont::-webkit-scrollbar {
                width: 8px;
                padding: 9px;
              }
              .lawBoxCont::-webkit-scrollbar-track {
                margin: 9px 0 1px;
              }
              .lawBoxCont::-webkit-scrollbar-thumb {
                background-color: #d1cbd6;
                height: 210px;
              }
              &:before {
                width: 95%;
                height: 60px;
                top: 42px;
                z-index: 1;
              }
              &:after { width: 95%; height: 52px; bottom: 0; }
            }
            .lawBoxCheck {
              text-align: center;
              margin-top: 30px;
              #uniform-check {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                span {
                  width: 100%;
                  height: 100%;
                  input { width: 100%; height: 100%; }
                  &.checked:before {
                    width: 24px;
                    height: 19px;
                    background-size: 24px auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  商品のご注文 確認画面
  -------------------------------------*/
  #PageOrder.pageConfirm {
    #Main {
      #ContBox01 {
        #formWrap {
          width: 1000px;
          padding: 66px 0 178px;
          margin: 0 auto;
          .txtWrong {
            p {
              line-height: 2;
              letter-spacing: .1em;
              text-align: center;
              margin-bottom: 0;
              &:first-of-type { margin-bottom: 32px; }
              &.error_messe {
                text-align: left;
                display: inline-block;
                position: relative;
                &:after {
                  display: inline-block;
                  content: '/';
                  margin-left: 4px;
                }
                &:last-of-type:after { display: none; }
              }
            }
          }
          .txtBtn { margin: 29px 0 0; }
        }
        .contSubBox {
          width: 1000px;
          margin: 0 auto;
          font-size: 16px;
          line-height: 1.5;
          .txtArea {
            p {
              line-height: 2;
              text-align: center;
            }
          }
          .formArea {
            margin-top: 82px;
            h3 {
              font-size: 20px;
              padding: 18px 0 15px;
            }
            .formPart {
              position: relative;
              padding: 63px 0 58px;
              border-bottom: 1px solid #ddd;
              .trapezoid {
                position: absolute;
                width: 111px;
                height: 29px;
                font-size: 12px;
                line-height: 30px;
                letter-spacing: .4em;
                padding-left: .4em;
                top: 60px;
                &:before, &:after {
                  border-right: 10px solid transparent;
                  border-left: 10px solid transparent;
                  width: calc(100% - 20px);
                }
                &:before { border-bottom: 15px solid #4a365b; }
                &:after { border-top: 15px solid #4a365b; }
              }
              .formInner {
                padding-left: 170px;
                display: flex;
                flex-wrap: wrap;
                tbody { width: 100%; }
                tr {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: top;
                  margin-top: 26px;
                  &:first-of-type { margin-top: 0; }
                }
                th {
                  width: 230px;
                  padding-right: 40px;
                  &:before {
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    padding: 0 5px 0 7px;
                    top: -1px;
                  }
                }
                td {
                  width: calc(100% - 230px);
                  p {
                    font-size: 16px;
                    line-height: 1.5;
                  }
                }
              }
            }
            .formPart01 {
              padding-bottom: 56px;
              tr:last-of-type {
                th {
                  position: relative;
                  padding-left: 50px;
                  &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
              }
            }
            .formPart02 {
              .trapezoid { width: 141px; }
            }
            .formPart04 {
              padding-bottom: 0;
              border-bottom-width: 0;
              .trapezoid { width: 126px; }
              .formInner {
                tr.noteLine {
                  margin-top: 38px;
                  td {
                    span.note {
                      padding-left: 17px;
                      font-size: 14px;
                      line-height: 1.7;
                    }
                  }
                }
                tr:nth-of-type(3) { margin-top: 38px; }
                tr:last-of-type {
                  th {
                    position: relative;
                    padding-left: 50px;
                    &:before {
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                }
              }
            }
            .btnArea { margin: 55px 0 0; }
            .txtBtn { margin: 29px 0 0; }
          }
        }
      }
    }
  }

  /*
  商品のご注文 送信完了画面
  -------------------------------------*/
  #PageOrder.pageThanks {
    padding-bottom: 120px;
    #Main {
      #ContBox01 {
        #ContSubBox01 {
          text-align: center;
          padding: 66px 0 187px;
          .txtArea {
            p {
              font-size: 16px;
              line-height: 2;
              letter-spacing: .1em;
              text-align: center;
            }
          }
          .telBtn { margin-top: 22px; }
          .txtBtn {
            font-size: 14px;
            margin: 57px 0 0;
          }
        }
      }
    }
  }

  /*
  商品紹介
  -------------------------------------*/
  #PageItem {
    #Main {
      .contBox {
        h2 {
          background-size: 29px auto;
          margin-bottom: 46px;
        }
        .contSubBox {
          width: 1000px;
          margin: 0 auto;
        }
        .subBoxTable {
          margin-bottom: 60px;
          th {
            width: 240px;
            font-size: 14px;
            padding: 15px 19px;
          }
          td {
            font-size: 14px;
            padding: 14px 15px 12px 19px;
            small {
              font-size: 12px;
              line-height: 1.6;
              margin-bottom: 4px;
              transform: translateY(3px);
            }
          }
        }
        .subBoxBtn {
          .btn {
            display: inline-block;
            width: 386px;
            height: 60px;
            background-position: right 17px center, left top;
            background-repeat: no-repeat, repeat-x;
            background-size: 12px auto;
            font-size: 16px;
            line-height: 60px;
            padding-left: .8em;
            &:first-of-type {
              margin-right: 47px;
              &:before {
                left: -11px;
              }
            }
          }
        }
      }
      #ContBox01 {
        padding-bottom: 120px;
        .contSubBox01 {
          display: flex;
          flex-wrap: wrap;
          height: 523px;
          margin-bottom: 60px;
          .subBox {
            &_left {
              width: 316px;
              transform: translateY(83px);
              .subBoxTxt {
                padding: 4px 8px 8px;
              }
              .trapezoid {
                width: 151px;
                height: 30px;
                font-size: 12px;
                line-height: 30px;
                padding-left: 5px;
                top: -36px;
                &:before, &:after {
                  width: calc(100% - 20px);
                  border-right-width: 10px;
                  border-left-width: 10px;
                }
                &:before {
                  border-bottom-width: 15px;
                }
                &:after {
                  border-top-width: 15px;
                }
              }
              h3 {
                font-size: 20px;
                left: 3px;
              }
              .txtList {
                ul {
                  margin: 20px 0 15px;
                  li {
                    font-size: 14px;
          					&:before {
          					  top: 8.5px;
          					}
                    & + li {
                      margin-top: 7px;
                    }
                  }
                }
                .btn {
                  height: 40px;
                  font-size: 14px;
                  line-height: 23px;
                  border: 10px solid;
                  margin-top: 20px;
                  transition: .3s linear;
                  span {
                    background-repeat: no-repeat;
          					background-position: right 4.5px !important;
                    background-size: 11px auto;
                    padding-right: 15px;
                  }
                }
              }
            }
            &_right {
              width: calc(100% - 316px);
              background-position: left 36px top;
              background-size: 463px auto;
              .subBoxImg {
                width: 320px;
                left: 86px;
                img { top: 92px; }
              }
              .subBoxTxt {
                right: 49px;
                span {
                  width: 20px;
                  background-size: 20px auto;
                  margin-left: 13px;
                  top: -3px;
                  &:nth-of-type(1) {
                    width: 17px;
                    background-size: 17px auto;
                    margin-left: 0;
                  }
                }
                p {
                  width: 47px;
                  background-size: 48px auto;
                  margin: 0 9px 0 7px;
                  top: 51px;
                }
              }
              .subBoxPrice {
                position: absolute;
                bottom: -7px;
                right: 0;
                li {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: .07em;
                  text-align: right;
                  &:nth-of-type(1) {
                    margin-bottom: 6px;
                  }
                  &:nth-of-type(2) {
                    margin-bottom: -2px;
                  }
                  small {
                    font-size: 10px;
                    letter-spacing: .05em;
                  }
                }
              }
            }
          }
        }
        .contSubBox02 {
          .subBoxTable {
            td {
              .reportBox{
                margin-top: 15px;
                width: 320px;
                figure{
                  width: 320px;
                  figcaption{
                    padding-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
      #ContBox02 {
        padding-top: 60px;
        padding-bottom: 140px;
        .contSubBox {
          h3 {
            font-size: 20px;
            letter-spacing: .25em;
            line-height: 32px;
            padding-top: 30px;
            padding-left: 4px;
            background-size: 17px auto;
            margin-bottom: 86px;
          }
          .subBoxCont {
            margin-bottom: 60px;
            .boxImg {
              float: left;
              width: 500px;
              padding-top: 8px;
            }
            .boxTxt {
              float: right;
              width: 440px;
              p {
                font-size: 16px;
                line-height: 2;
                letter-spacing: .091em;
                text-align: left;
                margin-bottom: 32px;
                &:last-of-type {
                  margin-bottom: 30px;
                }
              }
              small {
                font-size: 14px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }

  /*
  商品紹介 三日月
  -------------------------------------*/
  #PageItem.pageMikaduki {
    &.fixed #Container { padding-top: 156px; }
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_02.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_01.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_03.png);
            }
            &:hover {
              opacity: 1;
              background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_hover_02.png);
              &:before {
                background-image: url(../img/contents/item/btnBg_hover_01.png);
              }
              &:after {
                background-image: url(../img/contents/item/btnBg_hover_03.png);
              }
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          margin-bottom: 0;
          .subBox {
            &_left {
              .txtList {
                .btn {
                  border-image: url(../img/contents/item/frame_pc_01.png) 10 round;
                  &:hover {
                    opacity: 1;
                    background-color: #e6dfec;
                  }
                }
              }
            }
            &_right {
              .subBoxTxt {
                span {
                  &:nth-of-type(1) {
                    width: 19px;
                    height: 227px;
                    top: 171px;
                    // top: 279px;
                  }
                  &:nth-of-type(2) {
                    height: 235px;
                  }
                  &:nth-of-type(3) {
                    height: 261px;
                  }
                  &:nth-of-type(4) {
                    height: 97px;
                    top: -29px;
                  }
                }
                p {
                  height: 400px;
        					background-position: left bottom 15px;
                  top: 20px;
                }
              }
              .subBoxPrice {
                bottom: -70px;
                p {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.1em;
                  margin-bottom: 0;
                  span {
                    font-size: 12px;
                    color: #888;
                    &.right {
                      display: block;
                      text-align: right;
                    }
                  }
                  small {
                    display: inline-block;
                    font-size: 10px;
                    line-height: 20px;
                  }
                  &:first-of-type { margin-bottom: 9px; }
                }
              }
              .subBoxNote { display: none; }
            }
          }
        }
        .contSubBox02 {
          .subBoxNote {
            margin: 47px 0 60px;
            .flexBox {
              justify-content: center;
              .item {
                width: 50%;
                a:hover { opacity: 1; }
              }
            }
            .flexBox02 {
              padding: 15px 0;
              margin-top: 21px;
              .item {
                padding: 8px 0 13px;
                position: relative;
                &:before {
                  position: absolute;
                  display: none;
                  content: '';
                  width: 100%;
                  border-top: 1px solid #dddddd;
                  top: -15px;
                  left: 0;
                }
                & + .item {
                  margin-top: 30px;
                  &:before { display: block; }
                }
                &:nth-of-type(1) {
                  width: 100%;
                }
                &:nth-of-type(2) {
                  border-right: 1px solid #dddddd;
                }
              }
            }
          }
          .subBoxBtn { margin-top: 60px; }
        }
      }
      #ContBox03 {
        padding-top: 60px;
        padding-bottom: 142px;
        .contSubBox {
          h3 {
            font-size: 20px;
            letter-spacing: .25em;
            line-height: 32px;
            padding-top: 30px;
            padding-left: 4px;
            background-size: 17px auto;
            margin-bottom: 90px;
          }
          .subBox {
            .subBoxCont { position: relative; }
            .subBoxImg {
              width: 370px;
              height: 376px;
              background-repeat: no-repeat;
              background-position: center top;
              background-size: 100% auto;
              margin: 0 auto;
              img {
                width: 227px;
                transform: translateY(7px);
              }
            }
            .subBoxTxt {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              span {
                position: absolute;
                &:nth-of-type(1) {
                  width: 16px;
                  height: 116px;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(2) {
                  height: 213px;
                  top: 22px;
                  right: 108px;
                }
                &:nth-of-type(4) {
                  height: 237px;
                  top: 22px;
                  right: 36px;
                }
                &:nth-of-type(5) {
                  height: 276px;
                  right: 0;
                  top: 0;
                }
              }
              p {
                position: absolute;
                width: 90px;
                left: 33px;
                bottom: 0;
              }
            }
            .subBoxNote {
              margin-top: -46px;
              p {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.1em;
                text-align: right;
                margin-bottom: 0;
                small {
                  display: inline-block;
                  font-size: 10px;
                  line-height: 20px;
                }
                &:first-of-type { margin-bottom: 9px; }
              }
            }
            .subBoxDetail {
              margin-top: 25px;
              .item {
                width: 490px;
                padding: 10px 0;
                margin: 20px 20px 40px 0;
                &:nth-of-type(2n) { margin-right: 0; }
                &:nth-last-of-type(2), &:last-of-type {
                  margin-bottom: 0;
                }
                dt {
                  width: 181px;
                  border-right: 1px solid #ddd;
                  img {
                    width: 102px;
                    height: auto;
                    margin-bottom: 11px;
                  }
                  p {
                    text-align: center;
                    letter-spacing: 0.25em;
                    line-height: 24px;
                    margin-left: 4px;
                    margin-bottom: 0;
                    span {
                      display: block;
                      font-size: 12px;
                      letter-spacing: 0.25em;
                      line-height: 16px;
                      margin-top: 2px;
                    }
                    &.gray { margin-top: 2px; }
                  }
                }
                dd {
                  width: calc(100% - 161px);
                  padding: 10px 0 11px 30px;
                  p {
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    line-height: 28px;
                    margin-bottom: 18px;
                  }
                }
                .gray {
                  font-size: 12px;
                  color: #888;
                  letter-spacing: 0.1em;
                  line-height: 20px;
                  margin-bottom: 0;
                }
              }
            }
            .subBoxSur {
              margin-top: 64px;
              .trapezoid {
                width: 171px;
                height: 29px;
                font-size: 12px;
                letter-spacing: 0.4em;
                line-height: 29px;
                padding-left: 0.4em;
                top: -35px;
                &:before, &:after {
                  border-right-width: 10px;
                  border-left-width: 10px;
                  width: calc(100% - 20px);
                }
                &:before {
                  border-bottom-width: 15px;
                }
                &:after {
                  border-top-width: 15px;
                }
              }
              .sourse {
                width: 312px;
                padding: 19px 0 5px 28px;
                p {
                  font-size: 14px;
                  letter-spacing: 0.1em;
                  line-height: 28px;
                  padding-left: 16px;
                  &:before {
                    width: 9px;
                    height: 9px;
                    top: 9px;
                  }
                }
                &:first-of-type { padding-left: 9px; }
                &:nth-of-type(2) {
                  width: 334px;
                  position: relative;
                  &:before, &:after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: calc(100% + 42px);
                    background-color: #ddd;
                    top: -21px;
                  }
                  &:before { left: 0; }
                  &:after { right: 0; }
                }
              }
            }
          }
          .subBox01 {
            .subBoxTxt {
              width: 550px;
              height: 354px;
              top: 14px;
              span {
                &:nth-of-type(3) {
                  height: 185px;
                  top: 22px;
                  right: 72px;
                }
              }
              p {
                height: 272px;
                background-image: url(../img/contents/top/vertxt_20.png);
              }
            }
            .subBoxDetail {
              .item {
                &:before { background-image: url(../img/contents/item/boxBg_pc_01.png); }
                &:after { background-image: url(../img/contents/item/boxBg_pc_03.png); }
              }
              .item06 {
                dt {
                  p span { letter-spacing: 0.1em; }
                }
              }
            }
          }
          .subBox02 {
            margin-top: 75px;
            .subBoxImg {
              span { bottom: 29px; }
            }
            .subBoxTxt {
              width: 550px;
              height: 357px;
              top: 14px;
              span {
                &:nth-of-type(1) { bottom: 3px }
                &:nth-of-type(3) {
                  height: 245px;
                  top: 22px;
                  right: 72px;
                }
              }
              p {
                height: 276px;
                background-image: url(../img/contents/top/vertxt_26.png);
                left: 33px;
                bottom: 0;
              }
            }
            .subBoxDetail {
              .item {
                &:before { background-image: url(../img/contents/item/boxBg_pc_04.png); }
                &:after { background-image: url(../img/contents/item/boxBg_pc_06.png); }
              }
            }
          }
          .subBox03 {
            margin-top: 60px;
            .subBoxTable {
              td {
                small {
                  vertical-align: super;
                  font-size: 0.5em;
                  display: inline;
                  position: relative;
                  top: 3px;
                }
                .note {
                  li {
                    line-height: 24px;
                    padding-left: 26px;
                  }
                }
                p {
                  font-size: 14px;
                  letter-spacing: 0.1em;
                  line-height: 24px;
                  margin-bottom: 0;
                  & + p { margin-top: 12px; }
                }
              }
            }
          }
        }
      }
      #ContBox04 {
        .contSubBox {
          display: flex;
          justify-content: center;
          padding: 77px 0 64px;
          .subBox {
            width: 301px;
            .subBoxCont {
              height: auto;
              background-position: center top;
              background-repeat: no-repeat;
              background-size: 296px auto;
              padding-top: 7px;
            }
            .subBoxTxt {
              position: relative;
              width: 244px;
              height: 262px;
              margin: 0 auto 4px;
              left: 0;
              transform: translateX(0);
              p {
                width: 45px;
                height: 265px;
                top: -3px;
                left: 86px;
              }
              span {
                &:nth-of-type(1) {
                  width: 70px;
                  background-size: 100% auto;
                  left: 0;
                  bottom: 5px;
                }
                &:nth-of-type(2) {
                  height: 212px;
                  right: 72px;
                }
                &:nth-of-type(4) {
                  height: 277px;
                  top: -16px;
                  right: 0;
                }
              }
            }
            .subBoxItem {
              display: flex;
              justify-content: center;
              height: 70px;
              img {
                width: 70px;
                height: auto;
              }
            }
          }
          .subBox01 {
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_02_02.png);
            }
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 147px; }
                &:nth-of-type(3) {
                  height: 235px;
                  right: 36px;
                }
              }
            }
          }
          .subBox02 {
            margin-left: 94px;
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_03_02.png);
            }
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 139px; }
                &:nth-of-type(3) {
                  height: 212px;
                  right: 36px;
                }
              }
            }
          }
        }
      }
      #ContBox05 {
        .contSubBox {
          padding: 60px 0 130px;
          h3 {
            display: none;
            font-size: 20px;
            letter-spacing: .25em;
            line-height: 32px;
            padding-top: 30px;
            padding-left: 4px;
            background-size: 17px auto;
            margin-bottom: 90px;
          }
          .subBox {
            .subBoxCont {
              background-size: 370px auto;
              padding: 80px 0 15px;
              margin-bottom: 33px;
            }
            .subBoxTxt {
              width: 500px;
              height: 100%;
              left: 50%;
              transform: translateX(-50%);
              p {
                width: 24px;
                height: 193px;
                top: 95px;
                left: 67px;
              }
              span {
                width: 18px;
                height: 62px;
                top: 220px;
                left: 34px;
              }
            }
            .subBoxImg {
              img {
                width: 270px;
                height: 239px;
              }
            }
            .subBoxNote {
              margin-top: 29px;
              p {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.1em;
                text-align: right;
                margin-bottom: 0 !important;
                small {
                  display: inline-block;
                  font-size: 10px;
                  line-height: 20px;
                }
                &:first-of-type { margin-bottom: 9px; }
              }
            }
          }
        }
      }
    }
  }

  /*
  商品紹介 三日乃陽
  -------------------------------------*/
  #PageItem.pageHinata {
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            line-height: 61px;
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_05.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_04.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_06.png)
            }
            &:hover {
              opacity: 1;
              background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_hover_05.png);
              &:before {
                background-image: url(../img/contents/item/btnBg_hover_04.png);
              }
              &:after {
                background-image: url(../img/contents/item/btnBg_hover_06.png)
              }
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          .subBox {
            &_left {
              .txtList {
                .btn {
                  border-image: url(../img/contents/item/frame_pc_02.png) 10 round;
                  &:hover {
                    opacity: 1;
                    background-color: #f4ede5;
                  }
                }
              }
            }
            &_right {
              position: relative;
              .subBoxImg {
                width: 316px;
                img { top: 97px; }
                span {
                  position: absolute;
                  bottom: 21%;
                  right: 59px;
                  font-size: 14px;
                  letter-spacing: .1em;
                }
              }
              .subBoxTxt {
                span {
                  top: -2px;
                  &:nth-of-type(1) {
                    width: 19px;
                    height: 130px;
                    background-size: 19px auto;
                    top: 236px;
                  }
                  &:nth-of-type(2) {
                    height: 235px;
                  }
                  &:nth-of-type(3) {
                    height: 260px;
                  }
                  &:nth-of-type(4) {
                    height: 308px;
                    top: -28px;
                  }
                }
                p {
                  width: 49px;
                  height: 324px;
                  background-size: 48px auto;
                  margin-left: 5px;
                  top: 50px;
                }
              }
            }
          }
        }
      }
      #ContBox02 {
        padding-bottom: 144px;
      }
    }
  }

  /*
  商品紹介 福徳寿
  -------------------------------------*/
  #PageItem.pageFukutokuju {
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            line-height: 61px;
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_08.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_07.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_09.png)
            }
            &:hover {
              opacity: 1;
              background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_hover_08.png);
              &:before {
                background-image: url(../img/contents/item/btnBg_hover_07.png);
              }
              &:after {
                background-image: url(../img/contents/item/btnBg_hover_09.png)
              }
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          .subBox {
            &_left {
              .txtList {
                .btn {
                  border-image: url(../img/contents/item/frame_pc_03.png) 10 round;
                  &:hover {
                    opacity: 1;
                    background-color: #f5ebe6;
                  }
                }
              }
            }
            &_right {
              position: relative;
              .subBoxImg {
        				left: 97px;
                width: 306px;
                img { top: 93px; }
                span {
                  position: absolute;
                  bottom: 21%;
                  right: 37px;
                  font-size: 14px;
                  letter-spacing: .1em;
                }
              }
              .subBoxTxt {
                span {
                  top: -14px;
                  &:nth-of-type(1) {
                    width: 19px;
                    height: 130px;
                    background-size: 18px auto;
                    top: 205px;
                  }
                  &:nth-of-type(2) {
	                top: -1px;
                    height: 235px;
                  }
                  &:nth-of-type(3) {
                    height: 260px;
                  }
                  &:nth-of-type(4) {
                    height: 308px;
                    top: -28px;
                  }
                }
                p {
                  width: 49px;
                  height: 324px;
                  background-size: 47px auto;
                  margin-left: 5px;
                  top: 31px;
                }
              }
            }
          }
        }
      }
      #ContBox02 {
        padding-bottom: 144px;
      }
    }
  }

  /*
  構成三本柱
  -------------------------------------*/
  #PageMaterial.pageIndex{
  	#Main {
  		.contBox {
  			h2{
  				height: 336px;
  				background-position: center bottom 12px;
  			}
  			.mainImg{
  				margin-top: 63px;
  				background-image: url("../img/contents/material/mainimg.jpg");
  			}
  		}
  		#ContBox01{
  			margin-bottom: 12px;
  			.contSubBox{
  				padding-top: 59px;
  				padding-bottom: 81px;
  				width: 1000px;
  				.subBox{
  					&_title{
  						margin-bottom: 94px;
  						img{
  							width: 198px;
  						}
  						h3{
  							margin-top: 14px;
  							line-height: 32px;
  							font-size: 20px;
  							&::after{
  								bottom: -66px;
  							}
  						}
  					}
  					&_cont{
  						&::after {
  							content: "";
  							display: block;
  							clear: both;
  						}
  						&_right{
  							float: right;
  							margin-top: -8px;
  							width: 440px;
  							p{
  								margin-bottom: 31px;
  								line-height: 32px;
  								font-size: 16px;
  							}
  						}
  						&_left{
  							float: left;
  							width: 500px;
  							.subBoxTable {
  								margin-top: 30px;
  								th, td{
  									line-height: 24px;
  									font-size: 14px;
  								}
  								th {
  									padding: 13px 18px;
  									width: 119px;
  								}
  								td {
  									padding: 13px 20px;
  									line-height: 1.7;
  									letter-spacing: .1em;
  									p{
  										margin-bottom: 12px;
  										line-height: 24px;
  										font-size: 14px;
  									}
  								}
  							}
  						}
  					}
  				}
  			}
  		}
  	}
  }

  /*
  贈答品として
  -------------------------------------*/
  #PageGifts.pageIndex{
  	#Main {
  		.contBox {
  			h2 {
  				height: 300px;
  				background-size: 29px auto;
  			}
  			.mainImg{
  				margin-top: 68px;
  				background-image: url("../img/contents/gifts/mainimg.jpg");
  			}
  		}
  		#ContBox01{
  			margin-bottom: 140px;
  			.contSubBox{
  				padding-top: 90px;
  				width: 1000px;
  				&::after {
  					content: "";
  					display: block;
  					clear: both;
  				}
  				.imgBox{
  					width: 500px;
  					a{
  						&::after{
  							width: 30px;
  							height: 30px;
  							background-size: 16px auto;
  						}
  					}
   				}
  				.textBox{
  					display: flex;
  					-webkit-box-align: center;
  					-ms-flex-align: center;
  					align-items: center;
  					-webkit-box-pack: center;
  					-ms-flex-pack: center;
  					justify-content: center;
  					border-top: 1px solid #ddd;
  					/*padding-bottom: 9px;*/
  					width: 440px;
  					height: 309px;
  					h3{
  						margin-bottom: 25px;
  						padding-top: 31px;
  						background-size: 18px auto;
  						line-height: 32px;
  						font-size: 20px;
  						span{
  							font-size: 16px;
  							line-height: 24px;
  						}
  					}
  					p{
  						margin-bottom: 10px;
  						line-height: 32px;
  						font-size: 16px;
  					}
  					.note{
  						margin-top: 17px;
  						li{
  							padding-left: 13px;
  							line-height: 24px;
  							font-size: 14px;
  						}
  					}
   				}
  				&:nth-of-type(odd){
  					.imgBox{
  						float: left;
  					}
  					.textBox{
  						float: right;
  					}
  				}
  				&:nth-of-type(even){
  					.imgBox{
  						float: right;
  					}
  					.textBox{
  						float: left;
  					}
  				}
  			}
  		}
  	}
  }

  /*
  村井について
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main {
      h2 {
        height: 280px;
        background-size: 29px auto;
        margin-bottom: 85px;
      }
      #ContBox01 {
        .mainimg { background-size: cover; }
        h3 {
          font-size: 20px;
          line-height: 1.65;
          letter-spacing: 0.25em;
          background-size: 17px 17px;
          transform: translateX(1px);
          background-position: top left 49.5%;
          padding: 30px 0 15px;
          margin-bottom: 30px;
          &:after {
            height: 40px;
            background-color: #ddd;
            transform: translate(-1px, 15px);
          }
        }
        .box01 {
          width: 1000px;
          margin: 60px auto 90px;
          .boxCont {
            position: relative;
            width: 100%;
            height: 788px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("../img/contents/about/img_01_pc.jpg");
            background-size: 1000px auto;
            &::before{
              content: "";
              position: absolute;
              top: 10px;
              left: 10px;
              display: block;
              border: 1px solid #888;
              width: calc(100% - 20px);
              height: calc(100% - 20px);
            }
            .right {
              padding: 124px 59px 0 503px;
              color: #fff;
              ul {
                margin-top: -7px;
                li {
                  font-size: 16px;
                  line-height: 2;
                  letter-spacing: .1em;
                  margin-bottom: 16px;
                  text-align: left;
                  &:last-of-type { margin-bottom: 0; }
                }
              }
              b {
                margin: 29px 0 0 auto;
                width: 103px;
                height: 19px;
                background-size: 103px auto;
              }
            }
          }
        }
        .box02 {
          background: url(../img/contents/bg_04.jpg) top left;
          background-size: 50px 50px;
          padding: 10px;
          .boxWrap {
            border: 1px solid #7f718b;
          }
          .boxBody {
            display: flex;
            flex-wrap: wrap;
            max-width: 1000px;
            margin: 0 auto;
            padding: 49px 0 74px;
          }
          .boxCont01 {
            width: 70%;
            padding: 25px 58px 10px 0;
            text-align: left;
            border-right: 1px solid #7f718b;
            .boxInner {
              h3 {
                width: 93px;
                background-size: 28px 28px;
                background-position: left top 2px;
                margin-bottom: 0;
                padding: 0 0 0 40px;
                &:after { content: none; }
              }
              ul {
                padding-left: 1px;
                margin-top: 42px;
                li {
                  line-height: 2;
                  padding-left: 40px;
                  margin-bottom: 32px;
                  &:before { top: 1px; }
                  &:last-of-type {
                    font-size: 14px;
                    margin-bottom: 37px;
                  }
                }
              }
              small {
                display: block;
                font-size: 14px;
                line-height: 1;
                text-align: right;
              }
            }
          }
          .boxCont02 {
            width: 30%;
            color: #fff;
            padding: 30px 0 0 3px;
            h3 {
              background: url(../img/contents/icon_09.png) no-repeat;
              background-size: 17px auto;
              background-position: top left 40px;
              margin-bottom: 23px;
              &:after {
                background-color: #7f718b;
                transform: translate(-2px, 16px);
              }
            }
            p {
              font-size: 16px;
              letter-spacing: .1em;
              line-height: 2;
              margin-bottom: 20px;
            }
            img {
              width: 186px;
              transform: translateX(-2px);
            }
          }
        }
        .box03 {
          width: 1000px;
          margin: 60px auto 126px;
          .imageBox {
            width: 50%;
          }
          .textBox {
            width: 50%;
            padding-left: 60px;
            dl {
              dt {
                width: 120px;
                padding: 20px 0 15px;
              }
              dd {
                width: calc(100% - 120px);
                padding: 20px 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

/*#################### SP ####################*/
@media screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Header {
      .import {
        height: 40px;
        background-position: center right 15px;
        background-size: 8px auto;
        .import_in {
          padding-left: 15px;
          time {
            font-size: 10px;
            margin-right: 13px;
          }
          p {
            width: calc(100% - 135px);
            font-size: 12px;
            line-height: 40px;
          }
        }
      }
      #MainImg {
        width: 100%;
        height: 100%;
        .mainImg_slide {
          position: relative;
          width: 100%;
          height: 100%;
          left: 50%;
          transform: translate(-50%);
          &_item {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 767px auto;
            position: absolute;
            top: 0;
            left: 0;
          }
          &_item01 {
            background-image: url(../img/header/mainimg_SP_01.jpg);
          }
          &_item02 {
            background-image: url(../img/header/mainimg_SP_10.jpg);
            background-size: cover;
            .text {
              width: 71px;
              height: 131px;
              background-size: 71px auto;
              right: 30px;
              bottom: 102px;
            }
          }
          &_item03 {
            background-image: url(../img/header/mainimg_SP_09.jpg);
      			background-position: center top;
          }
        }
      }
    }
    #SpMenuBtn{
        transition: top 0.3s linear;
      &.scroll { top: 10px; }
    }
    #Main {
      #ContBox00 {
        padding-top: 75px;
        padding-bottom: 60px;
        &:before {
          display: none;
        }
        .bakedBox {
          &_in {
            flex-wrap: wrap;
            #BusinessHours {
              width: 100%;
              padding-top: 30px;
              margin-top: 15px;
              p {
                font-size: 12px;
                margin-bottom: 12px;
              }
            }
          }
          &_cont {
            width: 100%;
            padding: 18px 8.5px 9px;
            .trapezoid {
              width: 255px;
              height: 30px;
              font-size: 14px;
              letter-spacing: .3em;
              line-height: 31px;
              padding-left: 6px;
              top: -36px;
              &:before, &:after {
                width: calc(100% - 20px);
                border-right-width: 10px;
                border-left-width: 10px;
              }
              &:before { border-bottom-width: 15px; }
              &:after { border-top-width: 15px; }
            }
          }
          &_left {
            margin-bottom: 11px;
            p {
              font-size: 14px;
              line-height: 24px;
              letter-spacing: .1em;
              /*margin-bottom: 0;*/
              strong {
                font-size: 12px;
                color: #888;
                line-height: 20px;
                letter-spacing: .09em;
                margin-top: 1px;
              }
            }
            ul {
              li {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: .14em;
                padding-left: 20px;
                &:before {
                  top: 9px;
                  left: 4px;
                }
              }
            }
          }
          // &_right {
          //   img {
          //     width: 100%;
          //     height: auto;
          //   }
          // }
        }
      }
      #ContBox01 {
        &:before {
          padding-bottom: 20px;
          display: none;
        }
        h2 {
          margin-bottom: 32px;
          transform: translateY(-2px);
        }
        .contSubBox {
          padding: 0 15px;
          h3 {
            padding: 24px 0 13px;
            background-size: 14px 14px;
            font-size: 16px;
            line-height: 1.8;
          }
        }
        .subBox {
          max-width: 345px;
          padding: 0;
          margin: 0 auto;
          .subBoxCont {
            background-size: 232px auto;
          }
          .subBoxTxt {
            position: relative;
            span {
              position: absolute;
              background-size: 100% auto;
              &:first-of-type {
                width: 13px;
                height: 90px;
                left: 0;
                bottom: 0;
              }
              &:last-of-type {
                top: 0;
                right: 0;
              }
            }
            p {
              position: absolute;
              background-size: 100% auto;
            }
          }
          .subBoxBtn {
            width: 100%;
            max-width: 285px;
            height: 30px;
            border: 7px solid;
            span {
      				position: relative;
              font-size: 10px;
              letter-spacing: .3em;
              background-size: 7.5px auto;
              padding-right: 15px;
              position: relative;
              top: -6px;
              left: 5px;
      			  &::after{
      					content: "";
      					position: absolute;
      				  top: 1px;
      				  right: 0;
      					width: 10px;
      					height: 11px;
      					background-size: 10px 11px;
      					background-position: top left;
      					background-image: url(../img/contents/arrow_03.png);
      				}
            }
    			  &::after{
    				  display: none;
    			  }
          }
        }
        .contSubBox01 {
          .subBox01 {
            padding-top: 42px;
            padding-bottom: 55px;
            .subBoxCont {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              background-size: auto 346px;
              background-position: left bottom;
              padding-bottom: 50px;
            }
            .subBoxImg {
              width: 100%;
              img {
                width: 265px;
                height: auto;
              }
            }
            .subBoxTxt {
              width: 165px;
              height: 290px;
              // height: 356px;
              span {
                width: 15px;
                &:nth-of-type(1) {
                  width: 13px;
                  height: 180px;
                }
                &:nth-of-type(2) {
                  height: 177px;
                  top: 20px;
                  right: 60px;
                }
                &:nth-of-type(3) {
                  height: 195px;
                  top: 20px;
                  right: 30px;
                }
                &:nth-of-type(4) { height: 73px; }
              }
              p {
                width: 37px;
                height: 225px;
                // height: 308px;
                left: 25px;
                bottom: 4px;
                margin-bottom: 0;
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_05.png) 14 round;
              margin-top: 19px;
            }
          }
        }
        .contSubBox02 {
          padding-top: 45px;
          padding-bottom: 60px;
          .subBox {
            .subBoxTxt {
              width: 189px;
              height: 225px;
              span {
                width: 13px;
                &:nth-of-type(1) {
                  width: 11px;
                  height: 77px;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(2) {
                  height: 142px;
                  top: 15px;
                  right: 72px;
                }
                &:nth-of-type(3) {
                  top: 15px;
                  right: 48px;
                }
                &:nth-of-type(4) {
                  height: 158px;
                  top: 15px;
                  right: 24px;
                }
                &:nth-of-type(5) {
                  height: 184px;
                }
              }
              p {
                width: 63px;
                height: 187px;
                left: 20px;
                bottom: 0;
                margin-bottom: 0;
              }
            }
            .subBoxImg {
              width: 100%;
              height: 282px;
              background-size: 278px auto;
            }
            .subBoxDetail {
              width: 100%;
              .item {
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                margin-top: -2px;
                dt { width: 77px; }
                dd {
                  width: calc(100% - 77px);
                  padding-left: 14px;
                  p {
                    font-size: 14px;
                    line-height: 18px;
                    span { font-size: 12px; }
                    &.gray {
                      font-size: 12px;
                      line-height: 20px;
                      margin-top: 3px;
                    }
                  }
                }
                &:nth-of-type(2n) { padding-left: 45px; }
              }
              .item01 { margin-top: 0; }
            }
            .subBoxBtn { margin-top: 15px; }
          }
          .subBox01 {
            margin-top: 2px;
            margin-bottom: 34px;
            .subBoxTxt {
              span {
                width: 13px;
                &:nth-of-type(3) {
                  height: 134px;
                }
              }
            }
            .subBoxImg {
              margin-top: -60px;
              a {
                width: 171px;
                height: 178px;
                margin: 0 auto;
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_09.png) 14 round;
              span:after { background-image: url(../img/contents/arrow_07.png); }
            }
          }
          .subBox02 {
            .subBoxTxt {
              span {
                &:nth-of-type(3) {
                  height: 164px;
                }
              }
              p { height: 189px; }
            }
            .subBoxImg {
              margin-top: -62px;
              a {
                width: 171px;
                height: 176px;
                margin: 0 auto;
                transform: translateY(7px);
              }
              span { bottom: 17px; }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_06.png) 14 round;
              span:after { background-image: url(../img/contents/arrow_06.png); }
            }
          }
          .btnArea {
            flex-wrap: wrap;
            margin-top: 45px;
            a {
              width: 100%;
              height: 70px;
              line-height: 56px;
              text-align: center;
              border: 7px solid;
              span {
                background-position: left top 2px;
                &:after {
                  width: 8px;
                  height: 8px;
                  top: 24px;
                }
              }
              &:nth-of-type(1) {
                border-image: url(../img/contents/frame_06.png) 14 round;
                span {
                  padding: 0 17px 0 70px;
                  background-size: 57px auto;
                }
              }
              &:nth-of-type(2) {
                border-image: url(../img/contents/frame_09.png) 14 round;
                margin-top: 15px;
                span {
                  padding: 0 17px 0 76px;
                  background-size: 56px;
                }
              }
            }
          }
        }
        .contSubBox03 {
          padding: 0;
          .txtArea {
            padding: 45px 30px 60px;
            h3 { padding-bottom: 15px; }
            .listWrap {
              margin-top: -2px;
              .listItem {
                width: 100%;
                .trapezoid {
                  width: 140px;
                  height: 19px;
                  font-size: 10px;
                  letter-spacing: .2em;
                  line-height: 20px;
                  padding-left: 3px;
                  &:before, &:after {
                    width: calc(100% - 12px);
                    border-right: 6px solid transparent;
                    border-left: 6px solid transparent;
                  }
                  &:before { border-bottom: 10px solid #4a365b; }
                  &:after { border-top: 10px solid #4a365b; }
                }
                p {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: .1em;
                  margin: 7px 0 10px;
                }
                &:nth-of-type(3) {
                  margin-top: 1px;
                  .trapezoid {
                    width: 140px;
                  }
                }
              }
            }
          }
        }
        .contSubBox04 {
          padding: 30px 0 60px;
          .subBox {
            // padding-top: 30px;
            // padding-bottom: 60px;
            .subBoxCont {
              position: relative;
              background-repeat: no-repeat;
              background-position: center top 27px;
            }
            .subBoxTxt {
              position: relative;
              display: block;
              max-width: 345px;
              width: 166px;
              height: 200px;
              margin: 0 auto;
              span {
                width: 13px;
                &:nth-of-type(1) { width: 10px; }
                &:nth-of-type(2) {
                  height: 153px;
                  top: 15px;
                  right: 48px;
                }
                &:nth-of-type(3) {
                  top: 15px;
                  right: 24px;
                }
                &:nth-of-type(4) {
                  height: 200px;
                  top: 0;
                  right: 0;
                }
              }
            }
            .subBoxImg {
              a {
                display: block;
                margin: 0 auto;
              }
              p {
                line-height: 20px;
                margin-top: 15px;
              }
            }
            .subBoxItem {
              height: 53px;
              img {
                width: 53px;
                & + img { margin-left: 8px; }
              }
            }
            .subBoxBtn {
              display: none;
              margin-top: 10px;
              border: 7px solid;
            }
          }
          .subBox01 {
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_02_02.png);
              background-size: 221px auto;
            }
            .subBoxTxt {
              p {
                width: 29px;
                height: 172px;
                top: 28px;
                left: 60px;
              }
              span {
                &:nth-of-type(1) {
                  width: 50px;
                  height: 105px;
                  left: 0;
                  bottom: 1px;
                }
                &:nth-of-type(3) { height: 168px; }
              }
            }
            .subBoxImg {
              margin-top: 3px;
              a {
                width: 127px;
                height: 138px;
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_06.png) 14 round;
              color: #b0640c;
              span:after {
                background-image: url(../img/contents/arrow_06.png);
              }
            }
          }
          .subBox02 {
            margin-top: 30px;
            .subBoxCont {
              background-image: url(../img/contents/top/bg_circle_03_02.png);
              background-size: 221px auto;
            }
            .subBoxTxt {
              p {
                width: 29px;
                height: 170px;
                top: 32px;
                left: 60px;
              }
              span {
                &:nth-of-type(1) {
                  width: 50px;
                  height: 99px;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(3) { height: 152px; }
              }
            }
            .subBoxImg {
              a {
                width: 129px;
                height: 138px;
                &.pc_display { display: none; }
              }
            }
            .subBoxBtn {
              border-image: url(../img/contents/frame_09.png) 14 round;
              color: #d35c2a;
              span:after {
                background-image: url(../img/contents/arrow_07.png);
              }
              &.pc_display { display: none; }
            }
          }
        }
        .contSubBox05 {
          padding: 30px 0 60px;
          .subBox {
            .subBoxCont {
              background-size: 278px auto;
              padding: 62px 0 49px;
            }
            .subBoxTxt {
              width: 100%;
              max-width: 375px;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              p {
                width: 16px;
                height: 128px;
                top: 83px;
                left: 37px;
              }
              span {
                width: 12px;
                height: 41px;
                top: 171px;
                left: 17px;
                right: auto;
              }
            }
            .subBoxImg {
              float: none;
              a {
                display: block;
                width: 189px;
                height: 167px;
                margin: 0 auto;
              }
            }
            .subBoxBtn {
              width: 100%;
              max-width: 285px;
              height: 30px;
              color: #e60012;
              border: 7px solid;
              border-image: url(../img/contents/frame_10.png) 14 round;
              margin-top: -7px;
              span:after {
                background-image: url(../img/contents/arrow_08.png);
              }
            }
          }
        }
      }
      #ContBox02 {
        h2 { margin-bottom: 43px; }
        &:before { display: none; }
        .contSubBox {
          padding: 0;
          position: relative;
          .clickArea {
            height: 375px;
            box-shadow: inset 0 -150px 50px -50px rgba(0,0,0,0.6);
            .btnTxt {
              width: 100%;
              height: 62px;
              position: absolute;
              top: 218px;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              color: #fff;
              span {
                display: inline-block;
                width: 90px;
                height: 26px;
                font-size: 10px;
                line-height: 28px;
                background: url(../img/contents/top/bg_frame.png) top left;
                background-size: 90px 26px;
                margin-top: 9px;
                &::before {
                  content: '詳しく';
                  display: inline-block;
                }
                &::after {
                  content: '';
                  display: inline-block;
                  width: 9px;
                  height: 9px;
                  background: url(../img/contents/icon_03.png) center;
                  background-size: 9px 9px;
                  position: relative;
                  top: 1px;
                  left: 7px;
                }
              }
            }
          }
          .active {
            .btnTxt {
              span::before { content: '閉じる'; }
              span::after {
                background-image: url(../img/contents/icon_04.png);
                left: 6px;
              }
            }
          }
          .subBoxInner {
            padding: 45px 0px;
            h4 {
              font-size: 16px;
              line-height: 1.8;
              padding: 24px 0 13px;
              background-size: 14px 14px;
            }
          }
        }
        .contSubBox01 {
          .clickArea { background-image: url(../img/contents/top/img_03_02.jpg); }
          .subBoxInner {
            padding: 45px 15px;
            img {
              width: 285px;
              height: 176px;
            }
            .boxTxt {
              margin: 30px 0;
              a {
                height: 65px;
                font-size: 14px;
                letter-spacing: .1em;
                line-height: 51px;
                padding-left: 68px;
                border: 7px solid;
                border-image: url(../img/contents/frame_07.png) 14 round;
                &:before {
                  width: 49px;
                  height: calc(100% + 12px);
                  top: -6px;
                  left: 8px;
                }
                &:nth-of-type(1):before {
                  background-size: 22px auto;
                }
                &:nth-of-type(2):before {
                  background-size: 41px auto;
                }
                &:nth-of-type(3):before {
                  background-size: 49px auto;
                }
                & + a {
                  margin-top: 15px;
                }
              }
            }
            .boxBtn {
              width: 195px;
              height: 30px;
              text-decoration: none;
              line-height: 16px;
              border: 7px solid;
              border-image: url(../img/contents/frame_05.png) 14 round;
              span {
                font-size: 10px;
                letter-spacing: .3em;
                padding-right: 15px;
      				  background-repeat: no-repeat;
        				background-image: url(../img/contents/arrow_03.png);
        				background-position: right 4.5px !important;
                background-size: 8px auto;
                position: relative;
                left: 4px;
              }
            }
          }
        }
        .contSubBox02 {
          .clickArea { background-image: url(../img/contents/top/img_15.jpg); }
          .subBoxInner {
            padding-bottom: 0;
            .box01 {
              padding: 0 15px 48px;
              .boxCont {
      				  margin-bottom: 43px;
                .left {
                  img { width: 285px; }
                  p {
                    line-height: 1.7;
                    letter-spacing: .1em;
                    margin: 26px 0 40px;
                    padding: 0px 15px;
                  }
                }
                .right {
                  background-image: none;
                  padding: 41px 30px 21px;
                  border: 1px solid #ddd;
                  position: relative;
                  &:before, &:after {
                    content: '';
                    display: block;
                    width: calc(100% + 2px);
                    height: 42px;
                    position: absolute;
                    left: -1px;
                    background-image:
                      url(../img/contents/frame_03.jpg),
                      url(../img/contents/frame_04.jpg);
                    background-repeat: no-repeat, no-repeat;
                    background-position: top left, top right;
                    background-size: 21px 21px, 21px 21px;
                  }
                  &:before { top: -1px; }
                  &:after {
                    bottom: -1px;
                    transform: rotate(180deg);
                  }
                  h5 {
                    font-size: 16px;
                    line-height: 1.6;
                    margin-bottom: 25px;
                  }
                  p {
                    font-size: 12px;
                    line-height: 1.7;
                    letter-spacing: .1em;
                    margin-top: 13px;
                  }
                }
              }
      				>p{
      					padding-right: 15px;
      					padding-left: 15px;
      					text-align: justify;
      					line-height: 24px;
      					font-size: 14px;
      				}
      				.boxBtn {
      				  width: 195px;
      				  height: 30px;
      				  text-decoration: none;
      				  line-height: 16px;
      				  border: 7px solid;
      				  border-image: url(../img/contents/frame_05.png) 14 round;
      				  span {
        					font-size: 10px;
        					letter-spacing: .3em;
        					padding-right: 15px;
        					background-repeat: no-repeat;
        					background-image: url(../img/contents/arrow_03.png);
        					background-position: right 4.5px !important;
        					background-size: 8px auto;
        					position: relative;
        					left: 4px;
      				  }
      				}
            }
          }
        }
      }
      #ContBox03 {
        h2 { margin-bottom: 25px; }
        .leaderText {
          padding: 0 30px;
          margin-bottom: 60px;
          & > p {
            font-size: 14px;
            line-height: 24px;
          }
          & > span {
            font-size: 12px;
            letter-spacing: 0.1em;
            line-height: 22px;
          }
          .btnArea {
            margin-top: 12px;
            span {
              display: inline-block;
              color: #482862;
              margin: 6px auto 0;
            }
          }
        }
        .contSubBox {
          padding: 0 30px;
          margin-bottom: 59px;
        }
        .boxImage {
          margin-top: 59px;
          position: relative;
          .imgArea {
            .shopImage {
              width: 100%;
              height: 195px;
              background-image: url(../img/contents/top/img_08_01.jpg);
              background-size: cover;
              background-position: left bottom 30.5%;
            }
          }
          .verTxt {
            width: 30px;
            background-color: #4a365b;
            font-size: 14px;
            color: #fff;
            letter-spacing: 0.3em;
            line-height: 30px;
            writing-mode: vertical-lr;
            padding: 1px 0 4px;
            position: absolute;
            top: -3px;
            &:before, &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 12px;
              background-repeat: no-repeat;
              background-position: left top;
              background-size: 100% auto;
              left: 0;
            }
            &:before {
              background-image: url(../img/contents/top/bg_txt_02_01.png);
              top: -12px;
            }
            &:after {
              background-image: url(../img/contents/top/bg_txt_02_02.png);
              bottom: -12px;
            }
          }
        }
        .boxTxt {
          padding: 15px 0;
          p {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 0;
            span::after {
              width: 12px;
              height: 9px;
              background-size: 12px 9px;
              top: 1px;
              left: 7px;
            }
          }
        }
        .boxTable {
          width: 100%;
          tr {
            font-size: 12px;
            // td { padding: 12px 0 9px; }
            td {
              padding-top: 10px;
              a {
                &.sp_display { display: inline-block; }
                &.pc_display { display: none; }
              }
            }
            &.link {
              border-bottom-width: 0;
              td {
                padding: 20px 0 0;
                a {
                  display: none;
                  font-size: 14px;
                  color: #613a83;
                  letter-spacing: 0.1em;
                  line-height: 24px;
                  padding: 13px 0 14px;
                  border: 7px solid;
                  border-image: url(../img/contents/frame_05.png) 14 round;
                }
              }
            }
          }
        }
        .contSubBox01 {
          .boxImage {
            .verTxt {
              left: 15px;
            }
          }
        }
        .contSubBox02 {
          .boxImage {
            .imgArea .shopImage {
              background-image: url(../img/contents/top/img_08_02.jpg);
            }
            .verTxt01 {
              height: calc(100% + 26px);
              line-height: 29px;
              padding-bottom: 0;
              top: -18px;
              left: 15px;
            }
            .verTxt02 {
              left: 50px;
              top: -18px;
              padding: 4px 0 1px;
            }
          }
          .boxTxt { padding-top: 20px; }
        }
      }
      #ContBox04 {
        .listBoxArea {
          margin-top: 31px;
          height: auto;
          overflow: hidden;
          .swiper-wrapper { display: block; }
          .listBox {
            .label:nth-of-type(n+2) { margin-top: 5px; }
          }
        }
        .more {
          width: 165px;
          background-image: url(../img/contents/btn_frame_02.png);
          background-size: 165px auto;
          line-height: 25px;
          span {
            font-size: 10px;
            top: 2px;
            left: -3px;
          }
        }
        .swiper-button-next { display: none; }
        .swiper-button-prev { display: none; }
      }
      #ContBox05 {
        h2 { background-position: top -1px left; }
        .contSubBox01 {
          margin-top: 23px;
          padding: 0 30px;
        }
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main {
      #ContBox01 {
        h2 { background-position: center top -2px; }
        .txtWrong {
          padding: 0 30px;
          margin-bottom: 23px;
          p {
            font-size: 14px;
            line-height: 1.7;
            letter-spacing: .1em;
          }
        }
        #ContSubBox01 {
          padding: 0 30px;
          .txtArea {
            margin-bottom: 44px;
            p {
              font-size: 14px;
              line-height: 1.7;
            }
          }
          .formArea {
            h3 {
              height: 41px;
              line-height: 41px;
              letter-spacing: .15em;
              margin-bottom: 26px;
            }
            th {
              font-size: 14px;
              &::before {
                width: 30px;
                height: 15px;
                font-size: 10px;
                line-height: 15px;
              }
            }
            td {
              width: 100%;
              height: auto;
              margin-top: 8px;
              margin-bottom: 20px;
              border: none;
              font-size: 14px;
              line-height: 1.75;
            }
            tbody:last-of-type {
              td {
                height: auto;
                line-height: 1.7;
                margin-top: 8px;
                margin-bottom: 0;
              }
            }
            .sendBtn {
              margin-top: 34px;
              margin-bottom: 28px;
              p { border: none; }
            }
          }
        }
      }
    }
  }

  /*
  送信完了画面
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main {
      #ContBox01 {
        h2 { margin-bottom: 37px; }
        #ContSubBox01 {
          padding: 0 30px 82px;
          .txtArea {
            p {
              font-size: 14px;
              line-height: 1.75;
              letter-spacing: .1em;
              margin-bottom: 4px;
            }
          }
          .txtBtn {
            margin-top: 36px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /*
  新着情報
  -------------------------------------*/
  #PageNews.pageIndex {}

  /*
  新着情報 カテゴリー
  -------------------------------------*/
  #PageNews.pageCategory {
    #Main {
      #ContBox01 {
        .listMenu {
          .current-cat {
            position: relative;
            &::after {
              height: 46px;
              transform: translateX(-2px);
            }
          }
        }
        .listBoxArea { padding-bottom: 77px; }
      }
    }
  }

  /*
  新着情報 記事詳細
  -------------------------------------*/
  #PageNews.pageEntry {
    #Main {
      #ContBox01 {
        padding-bottom: 85px;
        .contSubBox {
          font-size: 12px;
          letter-spacing: .11em;
          padding: 0px 30px;
          h3 {
            font-size: 16px;
            line-height: 1.8;
            margin-bottom: 5px;
          }
          time {
            letter-spacing: .17em;
            margin-right: 9px;
          }
          .label {
            height: 19px;
            font-size: 10px;
            line-height: 19px;
            padding: 0 5px;
            &:last-of-type { margin-top: 5px; }
            &::before,&::after {
              width: 6px;
              height: 19px;
              background-size: 6px 19px;
            }
            &::before { left: -6px; }
            &::after { right: -6px; }
          }
          .entryBox {
            margin: 30px 0 27px;
            padding: 42px 0 35px;
            p {
              font-size: 14px;
              line-height: 1.75;
              letter-spacing: .1em;
            }
            .figerBox { margin-top: 24px; }
            &::before {
              content: '';
              position: absolute;
              width: 60px;
              height: 1px;
              background: #ddd;
              top: 0;
            }
          }
        }
        .linkArea {
          height: 18px;
          padding: 0 30px;
          overflow: auto;
          li {
            a {
              display: block;
              font-size: 12px;
              text-decoration: none;
            }
          }
          li.prev {
            left: 30px;
            a {
              width: 48px;
              background-size: 8px 8px;
              text-align: right;
              left: 0;
            }
          }
          li.top {
            left: 50%;
            transform: translateX(-47%);
          }
          li.next {
            right: 30px;
            a {
              width: 45px;
              background-size: 8px 8px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  /*
  商品のご予約
  -------------------------------------*/
  #PageReservation,
  #PageYukigaya {
    #Footer {
      height: 225px;
    }
  }
  #PageReservation.pageIndex,
  #PageYukigaya.pageIndex {
    #Main {
      #ContBox01 {
        h2 {
          height: 157px;
          background-size: 22px auto;
          margin-bottom: 45px;
        }
        .trapezoid {
          width: 96px;
          height: 24px;
          font-size: 10px;
          line-height: 24px;
          letter-spacing: .4em;
          padding-left: .4em;
          margin-bottom: 27px;
          &:before, &:after {
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            width: calc(100% - 16px);
          }
          &:before { border-bottom: 12px solid #4a365b; }
          &:after { border-top: 12px solid #4a365b; }
        }
        .mainImg {
          margin-bottom: 45px;
          background-image: url(../img/contents/reservation/mainimg_sp.jpg);
          .mainImgInner {
            p {
              width: 30px;
              font-size: 14px;
              line-height: 29px;
              padding: 3px 0 2px;
              left: 30px;
              top: -3px;
              &:nth-of-type(2) { left: 70px; }
              &:before {
                top: -12px;
                height: 12px;
                background-image: url(../img/contents/top/bg_txt_02_01.png);
              }
              &:after {
                bottom: -12px;
                height: 12px;
                background-image: url(../img/contents/top/bg_txt_02_02.png);
              }
            }
          }
        }
        #ContSubBox01 {
          padding: 0 30px;
          .txtArea {
            h3 {
              background-size: 14px auto;
              font-size: 16px;
              padding-top: 24px;
              padding-left: 0.25em;
              margin-bottom: 59px;
              &:after {
                bottom: -49px;
              }
            }
            & > p {
              line-height: 24px;
              margin-bottom: 24px;
            }
            .table {
              margin-bottom: 20px;
              h4 { margin-bottom: 30px; }
              table {
                &.sp_display { display: inline-table; }
                margin-bottom: 15px;
                th {
                  padding: 16px 0 15px;
                  border-top-width: 0.5px;
                  border-bottom-width: 0.5px;
                  border-right-width: 0.5px;
                }
                td {
                  padding: 15px 22px 16px;
                  border-top-width: 0;
                  border-bottom-width: 0;
                  span {
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                  }
                }
                tr {
                  td:last-of-type { border-right-width: 0.5px;}
                  &:last-of-type td { border-bottom-width: 1px; }
                }
              }
              .noteLists {
                .note {
                  font-size: 12px;
                  text-align: justify;
                  line-height: 20px;
                }
              }
            }
            .termTable {
              margin-bottom: 12px;
              table {
                th, td {
                  width: 100%;
                  display: block;
                }
                td p {
                  text-align: center;
                  & + p { margin-top: 5px; }
                }
              }
            }
          }
          .formArea {
            margin-top: 40px;
            h3 {
              letter-spacing: .25em;
              padding-left: .25em;
            }
            form { margin-top: 30px; }
            .formPart {
              .formInner {
                width: 100%;
                th {
                  padding-left: 40px;
                }
                td {
                  margin-bottom: 21px;
                  p, label {
                    font-size: 14px;
                    line-height: 24px;
                  }
                  .radio {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                    span { background-size: 40px 40px; }
                  }
                  small {
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: .1em;
                    text-align: justify;
                    padding-left: 15px;
                    &:before {
                      position: absolute;
                      left: 0;
                    }
                    &:first-of-type { margin-top: 6px; }
                  }
                }
              }
            }
            .formPart01 {
              padding-bottom: 9px;
              position: relative;
              &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 45px;
                height: 1px;
                background-color: #ddd;
              }
              .formInner {
                tr {
                  &:nth-of-type(1) td { margin-bottom: 27px; }
                  &:nth-of-type(3) td {
                    & > p {
                      font-size: 12px;
                      line-height: 20px;
                    }
                    small {
                      margin: 0 0 3px;
                      &:last-of-type { margin-bottom: 10px; }
                    }
                    dl {
                      padding-left: 20px;
                      dt {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 4px;
                        &:before {
                          width: 14px;
                          height: 14px;
                          background-size: 14px auto;
                          top: 4px;
                          left: -20px;
                        }
                      }
                      dd {
                        .option {
                          &_l { margin-right: 15px; }
                        }
                        p { margin-bottom: 4px; }
                        .selector { width: 115px !important; }
                      }
                      & + dl { margin-top: 15px; }
                    }
                  }
                  &:nth-of-type(4) td, &:last-of-type td {
                    margin-top: 2px;
                    p {
                      font-size: 12px;
                      line-height: 24px;
                      margin-bottom: 4px;
                    }
                  }
                  &:nth-of-type(4) td {
                    textarea { height: 120px; }
                  }
                  &:nth-of-type(5) td {
                    input { width: 85px; }
                  }
                  &:last-of-type td {
                    textarea { height: 240px; }
                  }
                }
              }
            }
            .formPart02 {
              margin-top: 30px;
              .formInner {
                width: 100%;
                td {
                  margin-bottom: 27px;
                }
                tr:last-of-type { td { margin-bottom: 26px; } }
                textarea {
                  height: 240px;
                  padding: 9px 14px;
                  font-size: 14px;
                  letter-spacing: .09em;
                  text-align: justify;
                }
              }
            }
            .privacy {
              h4 {
                letter-spacing: .3em;
                padding-left: .3em;
              }
            }
            .sendBtn {
              margin-bottom: 82px;
              p {
                letter-spacing: .2em;
                padding-left: .2em;
                transform: translateX(2px);
              }
            }
          }
        }
      }
    }
  }

  /*
  商品のご予約 確認画面
  -------------------------------------*/
  #PageReservation.pageConfirm,
  #PageYukigaya.pageConfirm {
    #Main {
      #ContBox01 {
        h2 {
          height: 157px;
          background-size: 22px auto;
          margin-bottom: 42px;
          transform: translateY(-2px);
        }
        .txtWrong {
          padding: 0 30px;
          margin-bottom: 23px;
          p {
            font-size: 14px;
            line-height: 1.7;
            letter-spacing: .1em;
          }
        }
        #ContSubBox01 {
          padding: 0 30px;
          .txtArea {
            margin-bottom: 40px;
            p {
              font-size: 14px;
              line-height: 1.7;
              &:last-of-type { margin-top: 3px; }
            }
          }
          .formArea {
            h3 {
              height: 41px;
              line-height: 41px;
              letter-spacing: .125em;
              padding-left: .125em;
              margin-bottom: 30px;
            }
            .trapezoid {
              width: 96px;
              height: 24px;
              font-size: 10px;
              line-height: 24px;
              letter-spacing: .4em;
              padding-left: .4em;
              margin-bottom: 27px;
              &:before, &:after {
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                width: calc(100% - 16px);
              }
              &:before { border-bottom: 12px solid #4a365b; }
              &:after { border-top: 12px solid #4a365b; }
            }
            .formPart {
              .formInner {
                width: 100%;
                th {
                  font-size: 14px;
                  padding-left: 40px;
                  &::before {
                    width: 30px;
                    height: 15px;
                    font-size: 10px;
                    line-height: 15px;
                    top: 2px;
                  }
                }
                td {
                  width: 100%;
                  height: auto;
                  margin-top: 7px;
                  margin-bottom: 21px;
                  border: none;
                  font-size: 14px;
                  line-height: 1.75;
                }
              }
            }
            .formPart01 {
              padding-bottom: 3px;
              position: relative;
              &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 45px;
                height: 1px;
                background-color: #ddd;
              }
              .formInner {
                tr:nth-of-type(3) {
                  td {
                    dl {
                      padding-left: 20px;
                      dt {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 4px;
                        &:before {
                          width: 14px;
                          height: 14px;
                          background-size: 14px auto;
                          top: 4px;
                          left: -20px;
                        }
                      }
                      dd {
                        p {
                          font-size: 14px;
                          line-height: 1.25;
                          margin-bottom: 0;
                          & + p { margin-top: 4px; }
                        }
                      }
                      & + dl { margin-top: 15px; }
                    }
                  }
                }
                tr:nth-of-type(4) {
                  td { margin-bottom: 20px; }
                }
              }
            }
            .formPart02 {
              margin-top: 30px;
              .formInner {
                tr:nth-of-type(4) {
                  td { margin-top: 6px; }
                }
              }
            }
            .sendBtn {
              margin-top: 11px;
              margin-bottom: 28px;
              p { border: none; }
            }
            .txtBtn { margin-bottom: 78px; }
          }
        }
      }
    }
  }

  /*
  商品のご予約 送信完了画面
  -------------------------------------*/
  #PageReservation.pageThanks,
  #PageYukigaya.pageThanks {
    #Main {
      #ContBox01 {
        h2 {
          height: 157px;
          background-size: 22px auto;
          margin-bottom: 42px;
          transform: translateY(-2px);
        }
        #ContSubBox01 {
          padding: 0 30px 75px;
          .txtArea {
            p {
              font-size: 14px;
              line-height: 24px;
              letter-spacing: .1em;
              margin-bottom: 4px;
              text-align: center;
            }
          }
          .telBtn .btn {
            margin-bottom: 4px;
            small {
              top: -3px;
              right: 9px;
            }
            p {
              letter-spacing: .185em;
              transform: translateY(-1px);
            }
          }
          .txtBtn {
            margin-top: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /*
  商品のご注文
  -------------------------------------*/
  #PageOrder {
    #Main {
      .contSubBox {
        padding: 0 30px;
      }
      #ContBox01 h2 {
        height: 159px;
        background-size: 22px auto;
      }
    }
  }
  #PageOrder.pageIndex {
    #Main {
      font-size: 14px;
      line-height: 1.7;
      #ContBox01 {
        .txtArea {
          margin-top: 41px;
          padding: 0 30px;
        }
        .pageNaviWrap {
          margin-top: 48px;
          padding: 0 30px;
          .navi {
            border-bottom: 1px solid #ddd;
            &:first-of-type { border-top: 1px solid #ddd; }
            a {
              display: block;
              width: 100%;
              font-size: 14px;
              padding: 20px 0 19px;
              background-position: center right 2px;
              background-size: 11px auto;
              img {
                width: 14px;
                height: auto;
                margin-right: 8px;
              }
            }
          }
        }
        .contSubBox {
          margin-top: 70px;
          .boxTitle {
            span { font-size: 16px; }
          }
          .boxCont {
            margin-top: 34px;
            span.note {
              display: block;
              text-align: left;
            }
          }
          p {
            font-size: 14px;
            line-height: 1.7;
            letter-spacing: .1em;
          }
          table.txtTable {
            th {
              padding: 14px 12px 14px 14px;
              vertical-align: middle;
            }
            td { padding: 14px 12px 14px 14px; }
          }
          .note {
            font-size: 12px;
            padding-left: 16px;
            &:before {
              height: auto;
            }
          }
          .kanji { padding-left: 30px; }
          .num{ padding-left: 20px; }
        }
        #ContSubBox01 {
          .boxCont {
            margin-top: 40px;
            table {
              &:first-of-type {
                tr {
                  border: 0;
                  &:last-of-type th { border-bottom-width: 1px; }
                }
                th {
                  width: 129px;
                  padding: 14px 18px;
                  letter-spacing: .05em;
                }
                td { text-align: left; }
              }
              &.sp_table {
                margin-top: 30px;
                th {
                  align-items: center;
                  width: 207px;
                  span { display: block; }
                  img {
                    display: block;
                    margin-top: 15px;
                  }
                }
                td {
                  vertical-align: middle;
                }
              }
            }
            ul.noteLists { margin-top: 15px; }
          }
        }
        #ContSubBox02 {
          margin-top: 66px;
          .boxCont {
            .tableWrap {
              margin-top: 25px;
              .tableBox {
                border-bottom: 1px solid #ddd;
                .click {
                  font-size: 14px;
                  letter-spacing: .1em;
                  text-align: left;
                  padding: 16px 0 15px;
                  background: url(../img/contents/icon_07.png) no-repeat center right 15px;
                  background-size: 9px auto;
                  &.active { background-image: url(../img/contents/icon_08.png); }
                }
                .showBox {
                  table {
                    th {
                      width: 101px;
                      border-bottom-width: 1px;
                    }
                    td {
                      vertical-align: middle;
                      &:nth-of-type(1) {
                        width: 120px;
                        small { font-size: 12px; }
                      }
                      &:nth-of-type(2) {
                        small { font-size: 11px; }
                      }
                    }
                  }
                  span.note {
                    margin-top: 6px;
                    margin-bottom: 16px;
                  }
                }
              }
              .tableBox:first-of-type {
                border-top: 1px solid #ddd;
              }
            }
            span.spNone { display: none; }
          }
        }
        #ContSubBox03 {
          margin-top: 70px;
          .boxCont {
            .tableWrap {
              margin-top: 25px;
              table.txtTable {
                th { width: 129px; }
              }
            }
            span.note { margin-top: 18px; }
          }
        }
        #ContSubBox04 {
          margin-top: 64px;
          .boxLeft {
            p.foreword { margin-top: 35px; }
            p:last-of-type { margin: 13px 0 0; }
          }
          .boxRight {
            margin-top: 65px;
            p { margin-bottom: 13px; }
          }
        }
        #ContSubBox05 {
          margin-top: 60px;
          .formArea {
            form { margin-top: 30px; }
            .formPart {
              position: relative;
              padding-bottom: 30px;
              margin-top: 30px;
              .formInner {
                width: 100%;
                margin-top: 25px;
                tr.address {
                  td:first-of-type {
                    height: 40px;
                    margin-bottom: 10px;
                    p { display: inline; }
                    input { margin-left: 6px; }
                  }
                }
                td {
                  height: auto;
                  margin-bottom: 20px;
                  input { height: 40px; }
                }
                p { margin-bottom: 0; }
                ul.noteLists,span.note { margin-top: 6px; }
                span.note { padding-right: 0; }
                label {
                  display: block;
                  margin-top: 10px;
                  &:first-of-type { margin-top: 0; }
                }
                div.radio {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                  span.checked:before {
                    width: 12px;
                    height: 12px;
                  }
                }
                textarea {
                  width: 100%;
                  height: 240px;
                  padding: 10px 14px 0;
                  background-color: #fff;
                  border: 1px solid #ddd;
                }
              }
              &:after {
                position: absolute;
                content: '';
                width: 45px;
                height: 1px;
                background-color: #ddd;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            .formPart01 {
              margin-top: 0;
              .trapezoid {
                &:before { border-bottom-width: 12.5px; }
              }
              .formInner {
                tr:nth-of-type(2) {
                  td {
                    height: auto;
                    p { height: 40px; }
                    input {
                      width: 150px;
                      margin-right: 10px;
                    }
                  }
                }
                tr:nth-of-type(3) {
                  td { margin-bottom: 0; }
                  p {
                    letter-spacing: .05em;
                    label.radioBtn {
                      margin-top: 0;
                      display: inline-block;
                    }
                    input[type="number"] {
                      display: inline-block;
                      width: 124px;
                      margin: 0 10px 0 12px;
                    }
                  }
                  .radioBtn { margin-top: 10px; }
                }
              }
            }
            .formPart02 {
              .trapezoid {
                width: 120px;
                &:before { border-bottom-width: 12.5px; }
              }
              .formInner {
                tr:last-of-type {
                  td {
                    margin-bottom: 0;
                    .selector select option:first-of-type { display: block; }
                  }
                }
              }
            }
            .formPart03 {
              .trapezoid {
                width: 96px;
                &:before { border-bottom-width: 12.5px; }
              }
              .formInner {
                tr:last-of-type td { margin-bottom: 0; }
              }
            }
            .formPart04 {
              padding-bottom: 0;
              &:before { border-bottom-width: 12.5px; }
              .trapezoid {
                width: 110px;
                &:after { bottom: 1px; }
              }
              .formInner {
                tr:first-of-type {
                  td { margin-bottom: 18px; }
                }
                tr.noteLine td { margin-bottom: 25px; }
                tr:last-of-type td { margin-bottom: 0; }
              }
            }
            .lawBoxWrap {
              margin-top: 40px;
              position: relative;
              .lawBoxCont {
                height: 386px;
                overflow-y: scroll;
                padding: 30px 0 22px;
                .contIn {
                  position: relative;
                  padding: 0 18px 0 22px;
                  dl {
                    font-size: 12px;
                    padding: 15px 0 16px;
                    &:first-of-type { padding-top: 0; }
                    &:last-of-type { padding-bottom: 0; }
                  }
                  dt {
                    width: 30.7%;
                    padding-right: 23px;
                  }
                  dd {
                    width: 69.3%;
                    color: #888;
                    p {
                      font-size: 12px;
                      line-height: 1.7;
                    }
                  }
                }
              }
              .lawBoxCont::-webkit-scrollbar {
                width: 6px;
                padding: 9px;
              }
              .lawBoxCont::-webkit-scrollbar-track {
                margin: 9px 0 1px;
              }
              .lawBoxCont::-webkit-scrollbar-thumb {
                background-color: #d1cbd6;
                height: 210px;
              }
              &:before {
                height: 30px;
                top: 43px;
                z-index: 1;
              }
              &:after {
                height: 22px;
                bottom: 0;
              }
            }
            .lawBoxCheck {
              margin-top: 34px;
              padding: 0 15px;
              text-align: center;
              .checkBtn {
                display: inline-block;
                position: relative;
                padding-left: 40px;
                text-align: left
              }
              #uniform-check {
                position: absolute;
                top: -4px;
                left: 0;
                width: 30px;
                height: 30px;
                margin-right: 10px;
                span {
                  width: 100%;
                  height: 100%;
                  &.checked {
                    &:before {
                      width: 18px;
                      height: 14px;
                      background-size: 18px auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  商品のご注文 確認画面
  -------------------------------------*/
  #PageOrder.pageConfirm {
    #Main {
      #ContBox01 {
        #formWrap {
          padding: 27px 30px 78px;
          .txtWrong {
            p {
              font-size: 14px;
              line-height: 1.7;
              letter-spacing: .1em;
              &:first-of-type { margin-bottom: 28px; }
            }
          }
          .txtBtn { margin: 28px 0 0; }
        }
        #ContSubBox01 {
          padding: 0;
          .txtArea {
            p {
              font-size: 14px;
              line-height: 1.7;
            }
          }
          .formArea {
            margin-top: 40px;
            h3 { padding: 9px 0 7px; }
            .trapezoid {
              width: 96px;
              height: 24px;
              font-size: 10px;
              line-height: 24px;
              letter-spacing: .4em;
              padding-left: .4em;
              margin-bottom: 27px;
              &:before, &:after {
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                width: calc(100% - 16px);
              }
              &:before { border-bottom: 12px solid #4a365b; }
              &:after { border-top: 12px solid #4a365b; }
            }
            .formPart {
              padding: 40px 0 21px;
              position: relative;
              &:after {
                position: absolute;
                content: '';
                width: 45px;
                height: 1px;
                background-color: #ddd;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
              }
              .formInner {
                width: 100%;
                tr {
                  margin-top: 22px;
                  &:first-of-type { margin-top: 0; }
                }
                th {
                  width: 100%;
                  font-size: 14px;
                  line-height: 1.7;
                  &:before {
                    height: 15px;
                    font-size: 10px;
                    line-height: 15px;
                    padding: 0 2px 0 4px;
                    top: -2px;
                  }
                }
                td {
                  width: 100%;
                  font-size: 14px;
                  line-height: 1.7;
                  margin-top: 6px;
                  p {
                    font-size: 14px;
                    line-height: 1.7;
                  }
                }
              }
            }
            .formPart02 {
              .trapezoid { width: 120px; }
            }
            .formPart04 {
              &:after { display: none; }
              .trapezoid { width: 110px; }
              .formInner {
                tr.noteLine {
                  margin-top: 20px;
                  td {
                    margin-top: 0;
                    span.note {
                      font-size: 12px;
                      line-height: 1.67;
                      padding-left: 17px;
                    }
                  }
                }
                tr:nth-of-type(3) { margin-top: 23px; }
              }
            }
            .sendBtn {
              margin: 14px 0 0;
              p { border: none; }
            }
            .txtBtn { margin: 28px 0 0; }
          }
        }
      }
    }
  }

  /*
  商品のご注文 送信完了画面
  -------------------------------------*/
  #PageOrder.pageThanks {
    padding-bottom: 80px;
    #Main {
      #ContBox01 {
        #ContSubBox01 {
          padding: 27px 30px 77px;
          .txtArea {
            p {
              font-size: 14px;
              line-height: 1.7;
              letter-spacing: .1em;
              margin: 0;
            }
          }
          .btnArea {
            margin-top: 25px;
            .telBtn .btn {
              small {
                top: -3px;
                right: 9px;
              }
              p {
                letter-spacing: .185em;
                transform: translateY(-1px);
              }
            }
            span {
              display: inline-block;
              margin-top: 8px;
            }
          }
          .txtBtn {
            margin-top: 39px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /*
  商品紹介
  -------------------------------------*/
  #PageItem {
    #Main {
      .contBox {
        .subBoxTable {
          margin-bottom: 45px;
          th {
            width: 110px;
            padding: 11px 0 12px 10px;
          }
          td {
            padding: 9px 10px 9px 8px;
            small {
              font-size: 10px;
              margin-top: 2px;
            }
          }
        }
        .subBoxBtn {
          .btn {
            display: block;
            width: calc(100% - 24px);
            height: 50px;
            background-repeat: no-repeat, repeat-x;
            background-position: right 6px center, left top;
            background-size: 10px auto, 12px auto;
            font-size: 14px;
            line-height: 50px;
            letter-spacing: .2em;
            padding-left: 5px;
            // margin: 0 auto;
            margin-left: 12px;
            &:first-of-type {
              margin-bottom: 15px;
            }
          }
        }
      }
      #ContBox01 {
        padding-bottom: 60px;
        h2 {
          position: relative;
          top: -2px;
          margin-bottom: 32px;
        }
        .contSubBox01 {
          display: flex;
          flex-direction: column;
          max-width: 375px;
          margin: 0 auto 30px;
          .subBox {
            &_left {
              order: 2;
              .subBoxTxt {
                padding-bottom: 9px;
              }
              .trapezoid {
                width: 124px;
                height: 24px;
                font-size: 10px;
                line-height: 24px;
                padding-left: 4px;
                top: -33px;
                &:before, &:after {
                  width: calc(100% - 14px);
                  border-right-width: 7px;
                  border-left-width: 7px;
                }
                &:before {
                  border-bottom-width: 12px;
                }
                &:after {
                  border-top-width: 12px;
                }
              }
              h3 {
                transform: translateY(-1px);
                padding-left: 4px;
                margin-bottom: 16px;
              }
              .txtList {
                padding: 0 8px;
                ul {
                  margin-bottom: 10px;
                  li {
                    font-size: 12px;
                    line-height: 2;
                    &:before {
					  top: 9px;
                      width: 5px;
                      height: 5px;
                    }
                  }
                }
                .btn {
                  span {
                    background-repeat: no-repeat;
                    background-position: right top 4px !important;
                    background-size: 8px auto;
                    padding-right: 15px;
                    position: relative;
                    top: 1px;
                    left: 4px;
                  }
                }
              }
            }
            &_right {
              order: 1;
              background-size: 232px auto;
              margin-bottom: 49px;
              .subBoxImg {
                width: 160px;
                left: 23px;
                img {
                  top: 45px;
                }
              }
              .subBoxTxt {
                top: -33px;
                right: 14px;
                span {
                  width: 14px;
                  background-size: 14px auto;
                  margin-left: 8px;
                  &:nth-of-type(1) {
                    width: 12px;
                    background-size: 12px auto;
                  }
                  &:nth-of-type(2) {
                    height: 165px;
                  }
                }
                p {
                  width: 33px;
                  background-size: 33px auto;
                }
              }
              .subBoxPrice {
                width: 222px;
                margin-top: 45px;
                // position: relative;
                // left: 43%;
                // transform: translateX(-50%);
                li {
                  text-align: right;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: .1em;
                  &:nth-of-type(1) {
                    margin-bottom: 9px;
                  }
                  small {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
        .contSubBox02 {
          padding: 0 30px;
        }
        .subBoxTable {
          td {
      			.reportBox{
      				margin-top: 7px;
      				figure {
      					figcaption{
      						padding-top: 10px;
      					}
      				}
      			}
          }
        }
      }
      #ContBox02 {
        padding-top: 45px;
        padding-bottom: 80px;
        &:before { display: none; }
        h3 {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: .25em;
          padding-top: 24px;
          padding-left: 4px;
          background-size: 14px auto;
          margin-bottom: 62px;
          &:after {
            bottom: -49px;
          }
        }
        .subBoxCont {
          margin-bottom: 41px;
          .boxImg {
            padding: 0 30px;
            margin-bottom: 26px;
          }
          .boxTxt {
            padding: 0 15px;
            p {
              font-size: 14px;
              line-height: 24px;
              letter-spacing: .09em;
              margin-bottom: 24px;
              &:last-of-type {
                margin-bottom: 26px;
              }
            }
          }
        }
        .subBoxBtn {
          padding: 0 15px;
        }
      }
      #ContBox03 {
        &:before { display: none; }
        h3 {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: .25em;
          padding-top: 24px;
          padding-left: 4px;
          background-size: 14px auto;
          margin-bottom: 62px;
          &:after {
            bottom: -49px;
          }
        }
      }
    }
  }

  /*
  商品紹介 三日月
  -------------------------------------*/
  #PageItem.pageMikaduki {
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_sp_02.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_sp_01.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_sp_03.png);
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          .subBox {
            &_left {
              .subBoxTxt {
                .btn {
                  margin-top: 15px;
                  font-size: 10px;
                  letter-spacing: .3em;
                  line-height: 1.6;
                  border: 7px solid;
                  border-image: url(../img/contents/frame_05.png) 14 round;
                }
              }
            }
            &_right {
              // margin-bottom: 41px;
              padding-bottom: 95px;
              .subBoxTxt {
                position: absolute;
                top: 0;
                span {
                  &:nth-of-type(1) {
                    height: 160px;
                    background-position: left top;
                    top: 24px;
                  }
                  &:nth-of-type(2) {
                    top: -33px;
                  }
                  &:nth-of-type(3) {
                    height: 182px;
                    top: -33px;
                  }
                  &:nth-of-type(4) {
                    height: 68px;
                    top: -52px;
                  }
                }
                p {
                  height: 200px;
                  background-position: left top;
                  margin: 0 2px 0 5px;
                  top: -6px;
                }
              }
              .subBoxPrice {
                // width: 265px;
                width: 200px;
                margin: 80px auto 22px;
                p {
                  margin-bottom: 0;
                  line-height: 20px;
                  letter-spacing: .1em;
                  font-size: 12px;
                  & + p { margin-top: 10px; }
                  small { font-size: 10px; }
                  span {
                    font-size: 10px;
                    color: #888;
                    position: relative;
                    left: 9px;
                    &.right {
                      display: block;
                      text-align: right;
                      left: 0;
                    }
                  }
                }
              }
              .subBoxNote {
                width: calc(100% - 30px);
                margin: 124px auto 0;
                .flexBox {
                  .item {
                    width: 100%;
                    p {
                      font-size: 12px;
                      letter-spacing: 0.075em;
                      line-height: 20px;
                      margin: 0 -5px 6px;
                      small { font-size: 10px; }
                    }
                    a {
                      font-size: 10px;
                      line-height: 18px;
                      &:hover { text-decoration: underline; }
                    }
                  }
                }
                .flexBox01 {
                  .item {
                    p span {
                      &:before {
                        width: 20px;
                        top: 8px;
                        left: -29px;
                      }
                      &:after {
                        width: 20px;
                        top: 8px;
                        right: -28px;
                      }
                    }
                  }
                }
                .flexBox02 {
                  margin-top: 11px;
                  .item {
                    padding: 16px 0 17px;
                    & + .item {
                      border-top: 1px solid #dddddd;
                    }
                  }
                }
              }
            }
          }
        }
        .contSubBox02 {
          .subBoxNote { display: none; }
        }
      }
      #ContBox03 {
        padding-top: 45px;
        padding-bottom: 73px;
        .contSubBox {
          h3:after { bottom: -47px; }
          .subBox {
            position: relative;
            max-width: 345px;
            margin: 0 auto;
            .subBoxCont {
              & > p {
                font-size: 12px;
                line-height: 18px;
              }
            }
            .subBoxImg {
              width: 232px;
              height: 236px;
              background-size: 100% auto;
              img {
                width: 142px;
                height: auto;
               transform: translate(-13px, 4px);
              }
            }
            .subBoxTxt {
              position: absolute;
              width: 155px;
              top: 2px;
              right: 15px;
              span {
                position: absolute;
                width: 12px;
                &:nth-of-type(1) {
                  height: 85px;
                  left: 0;
                  bottom: 0;
                }
                &:nth-of-type(2) {
                  height: 142px;
                  top: 33px;
                  right: 75px;
                }
                &:nth-of-type(3) {
                  top: 33px;
                  right: 50px;
                }
                &:nth-of-type(4) {
                  height: 158px;
                  top: 33px;
                  right: 25px;
                }
                &:nth-of-type(5) {
                  height: 180px;
                  top: 18px;
                  right: 0.5px;
                }
              }
              p {
                position: absolute;
                width: 32px;
                height: 100%;
                top: 0;
                left: 22px;
              }
            }
            .subBoxNote {
              margin-top: 86px;
              display: flex;
              justify-content: center;
              p {
                width: 50%;
                font-size: 12px;
                letter-spacing: 0.1em;
                line-height: 20px;
                margin-bottom: 0;
                span {
                  display: block;
                  margin-bottom: 11px;
                }
                &:nth-of-type(3) {
                  text-align: right;
                  margin-right: 13px;
                }
              }
              &:after {
                display: block;
                content: '';
                clear: both;
              }
            }
            .subBoxDetail {
              margin-top: 46px;
              .item {
                width: 100%;
                flex-wrap: wrap;
                padding: 4px 15px 5px 104px;
                p { margin-bottom: 0; }
                .gray {
                  font-size: 12px;
                  color: #888;
                  letter-spacing: 0.05em;
                  line-height: 16px;
                }
                dt {
                  width: 100%;
                  position: relative;
                  padding-bottom: 13px;
                  border-bottom: 1px solid #ddd;
                  img {
                    position: absolute;
                    width: 77px;
                    height: 77px;
                    top: -5px;
                    left: -90px;
                  }
                  p {
                    font-size: 14px;
                    letter-spacing: 0.25em;
                    line-height: 20px;
                    span {
                      display: block;
                      font-size: 12px;
                      letter-spacing: 0.25em;
                      line-height: 16px;
                      margin-top: 3px;
                    }
                    &.gray {
                      letter-spacing: 0.1em;
                      margin-top: 4px;
                    }
                  }
                }
                dd {
                  width: 100%;
                  padding-top: 10px;
                  p {
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    line-height: 22px;
                    &.gray { margin-top: 14px; }
                  }
                }
                & + .item { margin-top: 55px; }
              }
            }
            .subBoxSur {
              margin-top: 62px;
              .trapezoid {
                width: 139px;
                height: 24px;
                font-size: 10px;
                letter-spacing: 0.4em;
                line-height: 24px;
                padding-left: 0.4em;
                top: -33px;
                &:before, &:after {
                  border-right-width: 8px;
                  border-left-width: 8px;
                  width: calc(100% - 16px);
                }
                &:before {
                  border-bottom-width: 12px;
                }
                &:after {
                  border-top-width: 12px;
                }
              }
              .sourse {
                width: 100%;
                p {
                  font-size: 12px;
                  letter-spacing: 0.1em;
                  line-height: 22px;
                  padding-left: 15px;
                  &:before {
                    width: 7px;
                    height: 7px;
                    top: 7px;
                  }
                }
              }
            }
          }
          .subBox01 {
            .subBoxTxt {
              height: 297px;
              span {
                &:nth-of-type(3) {
                  height: 124px;
                }
              }
              p { background-image: url(../img/contents/item/vertxt_02.png); }
            }
            .subBoxNote {
              p:nth-of-type(3) {
                width: 83%;
              }
            }
            .subBoxDetail {
              .item01 {
                dt { padding-bottom: 12px; }
              }
              .item03, .item04, .item07, .item10 {
                width: calc(100% + 30px) !important;
              }
            }
            .subBoxSur {
              margin-top: 62px;
              .sourse {
                padding-top: 15px;
                padding-bottom: 27px;
                &:nth-of-type(2) {
                  position: relative;
                  padding-top: 26px;
                  padding-bottom: 27px;
                  &:before, &:after {
                    position: absolute;
                    content: '';
                    width: calc(100% + 42px);
                    height: 1px;
                    background-color: #ddd;
                    left: -21px;
                  }
                  &:before { top: 0; }
                  &:after { bottom: 0; }
                }
                &:last-of-type {
                  padding-top: 24px;
                  padding-bottom: 4px;
                }
              }
            }
          }
          .subBox02 {
            margin-top: 42px;
            .subBoxImg {
              span {
                left: 47px;
                bottom: -7px;
              }
            }
            .subBoxTxt {
              width: 155px;
              height: 277px;
              top: 1px;
              span {
                &:nth-of-type(3) {
                  height: 164px;
                  top: 34px;
                  right: 50px;
                }
              }
              p {
                width: 32px;
                background-image: url(../img/contents/item/vertxt_03.png);
              }
            }
            .subBoxNote {
              margin-top: 66px;
              p:nth-of-type(3) {
                width: 81%;
              }
            }
            .subBoxSur {
              .sourse {
                &:nth-of-type(2) {
                  padding-top: 20px;
                  padding-bottom: 6px;
                }
                &:first-of-type, &:last-of-type {
                  display: none;
                }
              }
            }
          }
          .subBox03 {
            max-width: none;
            margin-top: 30px;
            padding: 0 15px;
            .subBoxTable {
              td {
                line-height: 20px;
                small { display: inline; }
                .note {
                  li { padding-left: 27px; }
                }
                p {
                  font-size: 12px;
                  letter-spacing: .1em;
                  line-height: 20px;
                  & + p { margin-top: 10px; }
                }
              }
            }
          }
        }
      }
      #ContBox04 {
        &:before { display: none; }
        .contSubBox {
          padding: 30px 0 60px;
          .subBox {
            background-repeat: no-repeat;
            background-position: center top 27px;
            .subBoxCont { position: relative; }
            .subBoxTxt {
              position: relative;
              width: 166px;
              height: 201px;
              left: 0;
              transform: translateX(0);
              margin: 0 auto;
              p {
                width: 29px;
                height: 100%;
                top: 28px;
                left: 60px;
              }
              span {
                width: 13px;
                &:nth-of-type(1) {
                  width: 50px;
                  left: 0;
                  bottom: 3px;
                }
                &:nth-of-type(2) {
                  height: 153px;
                  top: 15px;
                  right: 48px;
                }
                &:nth-of-type(3) {
                  top: 15px;
                  right: 24px;
                }
                &:nth-of-type(4) {
                  height: 200px;
                  top: 0;
                  right: 0;
                }
              }
            }
            .subBoxImg {
              a {
                display: block;
                margin: 0 auto;
              }
              p {
                line-height: 20px;
                margin-top: 15px;
              }
            }
            .subBoxItem {
              height: 53px;
              img {
                width: 53px;
                & + img { margin-left: 8px; }
              }
            }
            .subBoxBtn {
              margin-top: 10px;
              border: 7px solid;
            }
          }
          .subBox01 {
            background-image: url(../img/contents/top/bg_circle_02_02.png);
            background-size: 221px auto;
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 105px; }
                &:nth-of-type(3) { height: 168px; }
              }
            }
          }
          .subBox02 {
            margin-top: 25px;
            background-image: url(../img/contents/top/bg_circle_03_02.png);
            background-size: 221px auto;
            .subBoxTxt {
              span {
                &:nth-of-type(1) { height: 99px; }
                &:nth-of-type(3) { height: 152px; }
              }
            }
          }
        }
      }
      #ContBox05 {
        padding: 50px 0 88px;
        &:before { display: none; }
        .contSubBox {
          h3 {
            margin-bottom: 62px;
            padding-top: 24px;
            padding-left: 4px;
            background-size: 14px auto;
            line-height: 28px;
            letter-spacing: .25em;
            font-size: 16px;
            &:after { bottom: -47px; }
          }
          .subBox {
            margin-bottom: 23px;
            .subBoxCont {
              background-size: 233px auto;
              padding: 50px 0 0;
            }
            .subBoxTxt {
              width: 100%;
              max-width: 375px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              p {
                width: 16px;
                height: 124px;
                top: 63px;
                left: 51px;
              }
              span {
                width: 14px;
                height: 48px;
                top: 139px;
                left: 28px;
                right: auto;
              }
            }
            .subBoxImg {
              img {
                display: block;
                width: 161px;
                height: 142px;
                margin: 0 auto;
              }
            }
            .subBoxNote {
              margin-top: 27px;
              p {
                font-size: 12px;
                letter-spacing: 0.1em;
                line-height: 20px;
                text-align: center;
                margin-bottom: 0;
                small {
                  display: inline-block;
                  font-size: 10px;
                }
              }
              &:after {
                display: block;
                content: '';
                clear: both;
              }
            }
          }
        }
      }
    }
  }

  /*
  商品紹介 三日乃陽
  -------------------------------------*/
  #PageItem.pageHinata {
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_sp_05.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_sp_04.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_sp_06.png);
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          .subBox {
            &_right {
              margin-bottom: 50px;
              .subBoxImg {
                position: relative;
                width: 158px;
                left: 24px;
                img { top: 46px; }
                span {
                  width: 108%;
                  font-size: 10px;
                  letter-spacing: .1em;
                  position: absolute;
                  left: -5px;
                  bottom: -71px;
                }
              }
              .subBoxTxt {
                span {
                  &:nth-of-type(1) {
                    width: 13px;
                    height: 91px;
                    background-size: 13px auto;
                    top: 166px;
                  }
                  &:nth-of-type(3) {
                    height: 181px;
                    top: 1px;
                  }
                  &:nth-of-type(4) {
                    height: 215px;
                    top: -18px;
                  }
                }
                p {
                  height: 223px;
                  top: 41px;
                  margin: 0 2px 0 4px;
                }
              }
              .subBoxPrice {
                margin-top: 48px;
                li {
                  letter-spacing: .09em;
                  &:last-of-type {
                    margin-top: -1px;
                  }
                }
              }
            }
            &_left {
              .txtList {
                .btn {
                  font-size: 10px;
                  letter-spacing: .3em;
                  line-height: 1.6;
                  border: 7px solid;
                  border-image: url(../img/contents/frame_06.png) 14 round;
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
      #ContBox02 {
        .subBoxCont {
          .boxImg {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  /*
  商品紹介 福徳寿
  -------------------------------------*/
  #PageItem.pageFukutokuju {
    #Main {
      .contBox {
        .subBoxBtn {
          .btn {
            background-image: url(../img/contents/arrow_02.png), url(../img/contents/item/btnBg_sp_08.png);
            &:before {
              background-image: url(../img/contents/item/btnBg_sp_07.png);
            }
            &:after {
              background-image: url(../img/contents/item/btnBg_sp_09.png);
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 {
          .subBox {
            &_right {
              margin-bottom: 50px;
              .subBoxImg {
                position: relative;
                width: 153px;
                left: 26px;
                img { top: 46px; }
                span {
                  width: 100%;
                  font-size: 10px;
                  letter-spacing: .1em;
                  position: absolute;
                  left: 2px;
                  bottom: -67px;
                }
              }
              .subBoxTxt {
                span {
                  &:nth-of-type(1) {
                    width: 13px;
                    height: 91px;
                    background-size: 13px auto;
                    top: 144px;
                  }
                  &:nth-of-type(3) {
                    height: 181px;
                    top: -9px;
                  }
                  &:nth-of-type(4) {
                    height: 215px;
                    top: -18px;
                  }
                }
                p {
                  height: 197px;
                  top: 26px;
                  margin: 0 2px 0 4px;
        				  background-size: 32.5px auto;
                }
              }
              .subBoxPrice {
                margin-top: 23px;
                li {
                  letter-spacing: .09em;
                  &:last-of-type {
                    margin-top: -1px;
                  }
                }
              }
            }
            &_left {
              .txtList {
                .btn {
                  font-size: 10px;
                  letter-spacing: .3em;
                  line-height: 1.6;
                  border: 7px solid;
                  border-image: url(../img/contents/frame_09.png) 14 round;
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
      #ContBox02 {
        .subBoxCont {
          .boxImg {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  /*
  構成三本柱
  -------------------------------------*/
  #PageMaterial.pageIndex{
  	#Main {
  		.contBox {
  			h2{
  				height: 135px;
  				background-size: 22px 133px;
  			}
  			.mainImg{
  				margin-top: 30px;
  				background-image: url("../img/contents/material/mainimg_SP.jpg");
  			}
  		}
  		#ContBox01{
  			margin-bottom: 12px;
  			.contSubBox{
  				padding-top: 45px;
  				padding-bottom: 54px;
  				.subBox{
  					&_title{
  						margin-bottom: 64px;
  						img{
  							width: 149px;
  						}
  						h3{
  							margin-top: 10px;
  							line-height: 24px;
  							font-size: 16px;
  							&::after{
  								bottom: -49px;
  							}
  						}
  					}
  					&_cont{
  						&_right{
  							padding: 42px 15px 0;
  							p{
  								margin-bottom: 24px;
  								line-height: 24px;
  								font-size: 14px;
  							}
  						}
  						&_left{
  							padding: 0 15px;
  							img{
  								width: 255px;
  							}
  							.subBoxTable {
  								margin-top: 30px;
  								th, td{
  									line-height: 20px;
  									font-size: 12px;
  								}
  								th {
  									padding: 9px;
  									width: 109px;
  								}
  								td {
  									padding: 9px 10px;
  									line-height: 1.7;
  									letter-spacing: .1em;
  									p{
  										margin-bottom: 12px;
  										line-height: 20px;
  										font-size: 12px;
  									}
  								}
  							}
  						}
  					}
  				}
  			}
  		}
  	}
  }

  /*
  贈答品として
  -------------------------------------*/
  #PageGifts.pageIndex{
  	#Main {
  		.contBox {
  			h2 {
  				height: 166px;
  				background-size: 21px auto;
  			}
  			.mainImg{
  				margin: 22px -30px 45px;
  				background-image: url("../img/contents/gifts/mainimg_sp.jpg");
  			}
  		}
  		#ContBox01{
  			margin-bottom: 60px;
  			padding-right: 30px;
  			padding-left: 30px;
  			.contSubBox{
  				border-top: 1px solid #ddd;
  				padding: 45px 0 0;
  				&:last-of-type{
  					border-bottom: 1px solid #ddd;
  				}
  				.imgBox{
  					margin: 0 auto 29px;
  					width: 255px;
  					a{
  						&::after{
  							width: 24px;
  							height: 24px;
  							background-size: 14px auto;
  						}
  					}
   				}
  				.textBox{
  					padding-bottom: 39px;
  					border-bottom: none;
  					h3{
  						margin-bottom: 17px;
  						padding-top: 24px;
  						background-size: 14px auto;
  						line-height: 24px;
  						font-size: 16px;
  						span{
  							font-size: 14px;
  							line-height: 24px;
  						}
  					}
  					p{
  						margin-bottom: 10px;
  						line-height: 24px;
  						font-size: 14px;
  					}
  					.note{
  						margin-top: 21px;
  						li{
  							padding-left: 13px;
  							line-height: 20px;
  							letter-spacing: .08em;
  							font-size: 12px;
  						}
  					}
   				}
  			}
  		}
  	}
  }

  /*
  村井について
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main {
      #ContBox01 {
        &:before { padding-bottom: 18px; }
        h2 {
          height: 149px;
          background-size: 21px auto;
          margin-bottom: 45px;
        }
        .mainImg {
          background-image: url(../img/contents/about/mainimage_sp.jpg);
          margin-bottom: 45px;
        }
        h3 {
          font-size: 16px;
          line-height: 1.8;
          padding: 22px 0 13px;
          background-size: 14px 14px;
          &::after { background-color: #ddd; }
        }
        .contSubBox01 { padding: 0; }
        .box01 {
          .boxCont {
            background-position: center top;
            background-image: url("../img/contents/about/img_01_sp.jpg");
            background-size: 100% auto;
            background-color: #000;
            /*.left {
              width: 100%;
              text-align: center;
              img {
                width: 255px;
                height: 255px;
              }
              b {
                width: 87px;
                height: 16px;
                margin: 15px auto 26px;
                background-size: 87px 16px;
              }
            }*/
            .right {
              padding: 218px 27px 59px 30px;
              ul {
                text-align: left;
                margin-bottom: 42px;
                li {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: .1em;
                  text-align: justify;
                  margin-bottom: 12px;
                  &:last-of-type { margin-bottom: 0; }
                }
              }
              b{
                margin: -31px 3px 0 auto;
                width: 87px;
                height: 19px;
                background-size: 87px auto;
              }
            }
          }
        }
        .box02 {
          background: url(../img/contents/bg_04.jpg) top left;
          padding: 5px;
          .boxWrap { border: 1px solid #7f718b; }
          .boxCont01 {
            padding: 0 24px;
            .boxInner {
              font-size: 14px;
              padding: 39px 0 32px;
              border-bottom: 1px solid #7f718b;
              h3 {
                background-size: 24px 24px;
                padding-top: 34px;
              }
              ul {
                li:last-of-type { margin-bottom: 27px; }
              }
              small {
                display: block;
                text-align: right;
              }
            }
          }
          .boxCont02 {
            padding: 35px 15px 39px;
            color: #fff;
            h3 {
              background: url(../img/contents/icon_09.png) no-repeat center top;
              background-size: 14px auto;
              padding-bottom: 10px;
            }
            p {
              text-align: center;
              font-size: 14px;
              line-height: 1.7;
              letter-spacing: .11em;
            }
            img {
              width: 155px;
              height: auto;
              margin-top: 10px;
            }
          }
        }
        .box03 {
          padding: 45px 30px 70px;
          h3 { padding-bottom: 15px; }
          .boxCont {
            flex-wrap: wrap;
            .imageBox { width: 100%; }
            .textBox {
              width: 100%;
              margin-top: 45px;
              dl {
                flex-wrap: wrap;
                padding: 15px 0 17px;
                dt, dd { width: 100%; }
                dt { margin: -0.5px 0 4px; }
              }
            }
          }
        }
      }
    }
  }

}


@media screen and (max-width: 375.99px) {
  #Page.pageIndex {
    #Main {
      #ContBox02 {
        .contSubBox01 {
          .clickArea { background-position: center top; }
    			.subBoxInner {
    				padding: 45px 30px 58px;
    				.boxTxt{
    					a:nth-of-type(1){
    						padding-top: 3px;
    						line-height: 24px;
    					}
    				}
    			}
        }
        .contSubBox02 {
          .subBoxInner {
            .box01 ul { padding: 0px 15px; }
          }
        }
      }
      #ContBox03 {
        .boxImage {
          .imgArea .shopImage { background-position: left bottom; }
        }
      }
    }
  }
  #PageOrder.pageIndex #Main {
    #ContBox01 {
      .contSubBox01 {
        table.sp_table th { width: 65.7%; }
      }
      .contSubBox02 {
        .boxCont .tableWrap .showBox {
          th { width: 32%; }
          td {
            &:nth-of-type(1) { width: 38%; }
            &:nth-of-type(2) { width: 30%; }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360.99px) {
  #PageItem.pageMikaduki {
    #Main {
      #ContBox03 {
        .contSubBox {
          .subBox {
            .subBoxImg {
              img { transform: translate(-25px, 4px); }
            }
          }
          .subBox02 {
            .subBoxImg {
              span { left: 30px; }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320.99px) {
  #Page.pageIndex {
    #Main {
      #ContBox01 {
        .subBox01 {
          background-position: top 20px left 2px;
          .subBoxImg {
            margin-left: 0;
            margin-right: 0;
          }
          .subBoxTxt span  { margin-left: 5px; }
        }
        .subBox02 {
          background-position: top 20px left 2px;
          .subBoxImg {
            margin-left: 0;
            margin-right: 0;
          }
          .subBoxTxt span  { margin-left: 5px; }
        }
        .subBox03 {
          background-position: top 20px left 2px;
          .subBoxImg {
            margin-left: 0;
            margin-right: 0;
          }
          .subBoxTxt span  { margin-left: 5px; }
        }
        .contSubBox02 {
          .subBoxTable { width: 100%; }
        }
      }
      #ContBox02 {
        .contSubBox01 {
          .subBoxInner img {
            position: relative;
            left: -13px;
          }
        }
      }
      #ContBox04 {
        .contSubBox02 {
          ul li .image { vertical-align: middle; }
        }
      }
    }
  }
  #PageOrder.pageIndex {
    #Main {
      table.txtTable {
        th { width: 50%; }
        td { width: 50%; }
      }
      #ContBox01 {
        #ContSubBox01 {
          .boxCont table.sp_table {
            th { width: 65%; }
            td { width: 35%; }
          }
        }
        #ContSubBox02 {
          .boxCont .tableWrap .tableBox .showBox {
            table.txtTable {
              th { width: 33.3%; }
              td { width: 33.3%; }
            }
          }
        }
      }
    }
  }
  #PageItem {
    #Main {
      #ContBox01 {
        .contSubBox01 {
          .subBox_right {
            .subBoxCont {
              height: 229px;
            }
            .subBoxImg {
              left: 0 !important;
              span {
                bottom: 20px !important;
                left: 16px !important;
              }
            }
            .subBoxTxt {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }
  #PageItem.pageMikaduki {
    #Main {
      #ContBox01 {
        .contSubBox01 {
          .subBox_right {
            .subBoxTxt span:nth-of-type(1) {
              top: 140px;
            }
          }
        }
      }
      #ContBox03 {
        .contSubBox {
          .subBox {
            .subBoxImg {
              justify-content: left;
              img { transform: translateY(4px); }
            }
            .subBoxTxt { right: 0; }
          }
          .subBox02 {
            .subBoxImg {
              span { left: 7px; }
            }
          }
        }
      }
    }
  }
}




/* safari */
_::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #ContBox01 .subBox_right .subBoxTxt p {
  transform: translateY(6%);
}
_::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
  transform: translateY(-48%);
}

_::-webkit-full-page-media, _:future, :root #PageItem.pageHinata #ContBox01 .subBox_right .subBoxTxt p {
  transform: translateY(5%);
}
_::-webkit-full-page-media, _:future, :root #PageItem.pageHinata #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
  transform: translateY(-52%);
}
_::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #ContBox01 .subBox_right .subBoxTxt p {
  transform: translateY(5%);
}
_::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
  transform: translateY(-52.5%);
}
_::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #Main #ContBox01 .contSubBox01 .subBox_right .subBoxPrice li:nth-of-type(1){
  transform: translateX(9px);
}
_::-webkit-full-page-media, _:future, :root #PageReservation.pageIndex #Main .formArea .formInner select::-ms-expand {
  display: none;
}

@media print, screen and (min-width: 768px) {
  _::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    transform: translateY(-8.5%);
  }
  _::-webkit-full-page-media, _:future, :root #PageItem.pageHinata #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    transform: translateY(-21%);
  }
  _::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #ContBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    transform: translateY(-21%);
  }
  _::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    top: 215px;
  }
}
@media print, screen and (max-width: 768px) {
  _::-webkit-full-page-media, _:future, :root #PageItem {}
  /* safariのみ */
  _:lang(x)+_:-webkit-full-screen-document, #Page.pageIndex #Main #ContBox01 .contSubBox01 .subBox01 .subBoxTxt span:nth-of-type(1) {
    // transform: translateY(12px);
  }
   _:lang(x)+_:-webkit-full-screen-document, #PageItem #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt {
    padding-left: 20px;
  }
  _:lang(x)+_:-webkit-full-screen-document, #PageItem.pageMikaduki #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt p {
    top: -21px;
  }
  // _::-webkit-full-page-media, _:future, :root #PageItem.pageHinata #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt p,
  // _::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt p {
  //   top: 53px;
  // }

  // _::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1),
  _::-webkit-full-page-media, _:future, :root #PageItem.pageHinata #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1),
  _::-webkit-full-page-media, _:future, :root #PageItem.pageFukutokuju #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    position: absolute;
    top: auto;
    left: 4px;
    margin-left: 0;
  }
  _::-webkit-full-page-media, _:future, :root #PageItem.pageMikaduki #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    top: 100px;
    // bottom: -9px;
  }
  #PageItem.pageHinata #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    bottom: -67px;
  }
  #PageItem.pageFukutokuju #Main #ContBox01 .contSubBox01 .subBox_right .subBoxTxt span:nth-of-type(1) {
    bottom: -42px;
  }

   _:lang(x)+_:-webkit-full-screen-document, #PageItem.pageMikaduki #Main #ContBox01 .contSubBox01 .subBox_right .subBoxPrice {
    margin-top: 145px;
   }

  #Page.pageIndex #Main #ContBox03 .boxImage .verTxt span,
  #PageYukigaya.pageIndex #Main #ContBox01 .mainImg .mainImgInner p span {
    position: relative;
    left: 2px;
  }
}

/* IE */
@media screen\0 {
  #PageContact #Header, #PageNews #Header, #PageReservation #Header {
    .innerBasic h1 a { background-image: url(../img/header/logo_black.png); }
  }
  #Page.pageIndex {
    #Main {
      #ContBox03 {
        .contSubBox01 {
          display: block;
          height: 426px;
          position: relative;
          overflow: auto;
          div { position: absolute; }
          .boxImage {
            top: 0;
            left: 0;
            width: 640px;
            height: 100%;
          }
          .boxTxt {
            top: 0;
            right: 0;
            width: 300px;
            height: 171px;
          }
          .boxTable {
            position: absolute;
            top: 171px;
            right: 0;
            width: 300px;
            height: 181px;
          }
        }
      }
      #ContBox05 .contSubBox01 {
        .formArea {
          .formInner {
            table-layout: auto;
            table-layout: fixed;
            tr,th,td { width: 485px; }
            .right { float: right; }
            .left { float: left; }
            .bottom {
              clear: both;
              tr, th, td { width: 1000px; }
            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm {
    #Main {
      #ContSubBox01 {
        .formArea .formInner {
          tr,th,td { width: 485px; }
          .right { float: right; }
          .left { float: left; }
          .bottom {
            clear: both;
            tr, th, td { width: 1000px; }
          }
        }
      }
    }
  }
  #PageReservation.pageIndex {
    #Main #ContBox01 {
      #ContSubBox01 {
        .formArea .formPart .formInner {
          td {
            width: 100%;
            max-width: 600px;
          }
        }
        .formArea .formPart01 .formInner {
          tr { width: 830px; }
        }
      }
    }
  }
  #Main {
    .listBoxArea ul li a .listBox {
      p {
        height: 64px;
        overflow: hidden;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  #Page.pageIndex #Main {
    #ContBox01 {
      .subBox02 .subBoxTxt {
        .txtList ul li { letter-spacing: .19em; }
      }
    }
  }
}

/* Edge */
@supports (-ms-ime-align: auto) {
  #Main {
    .privacy {
      &:before, &:after { top: -1.5px; }
    }
  }
  #PageReservation.pageIndex {
    #Main #ContBox01 {
      #ContSubBox01 {
        .formArea .formPart .formInner {
          td {
            width: 100%;
            max-width: 600px;
          }
        }
        .formArea .formPart01 .formInner {
          tr { width: 830px; }
        }
      }
    }
  }
}